import React from "react";
import loadingGifWhite from "../Assets/Loading/loading-white.gif";
import loadingGifBlack from "../Assets/Loading/loading-black.gif";
import loadingGifBlue from "../Assets/Loading/loading-blue.gif";
import loadingGifBlueGray from "../Assets/Loading/loading-blue-gray.gif";

const Loading = (props) => {
  return (
    <React.Fragment>
      {props.color === "white" && (
        <p style={{ textAlign: "center" }}>
          <img src={loadingGifWhite} />
        </p>
      )}
      {props.color === "black" && (
        <p style={{ textAlign: "center" }}>
          <img src={loadingGifBlack} />
        </p>
      )}
      {props.color === "blue" && (
        <p style={{ textAlign: "center" }}>
          <img src={loadingGifBlue} />
        </p>
      )}
      {props.color === "blue-gray" && (
        <p style={{ textAlign: "center" }}>
          <img src={loadingGifBlueGray} />
        </p>
      )}
    </React.Fragment>
  );
};

export default Loading;
