import React, { useEffect } from 'react'
import logo from '../../components/Assets/GoogleLogo/googleLogo.jpg'
import { useGoogleLogin } from "@react-oauth/google";
import { useRecoilState } from 'recoil';
import {
  sendUserCred,
  userAtom,
  groupsAtom,
  leadingStorage,
  setUserCredentials
} from "../../states/auth";
import Cookies from 'js-cookie';

const LoginPage = () => {

  var extension = window.location.origin.endsWith('.work') ? 'work' : 'dev'

  const [groups, setGroups] = useRecoilState(groupsAtom)
  const [user, setUser] = useRecoilState(userAtom)

  const updateUserInfo = (userInfo) => {

    let groups = userInfo.groups

    let user = {
      avatar: userInfo.avatar,
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      name: userInfo.fullName
    }

    sessionStorage.setItem(`${leadingStorage}Groups`, JSON.stringify(groups))
    sessionStorage.setItem(`${leadingStorage}UserData`, JSON.stringify(user))

    setGroups(groups)
    setUser(user)

    const returnLoc = sessionStorage.getItem(`${extension}PiManReturnLocation`)
    sessionStorage.removeItem(`${extension}PiManReturnLocation`)
    window.location = returnLoc ? returnLoc : window.location.href.includes('/auth') ? '/' : window.location.href
  }

  const checkIfLoggedIn = async () => {
    const userInfo = await setUserCredentials()
    if (userInfo) {
      updateUserInfo(userInfo)
    }
  }

  useEffect(() => {
    if (Cookies.get(`${leadingStorage}JWT`)) {
      checkIfLoggedIn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Function used to show the popup that lets someone sign in with google.
   * @func
   */
  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      var userInfo = await sendUserCred(code)
      updateUserInfo(userInfo)
    },
    flow: "auth-code",
  });
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          width: '100vw',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          background: "#111111",
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: 99998,
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyItems: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: '#181818',
            color: '#dddddd',
            padding: '15px',
            borderRadius: '5px',
            boxShadow: "0px 0px 20px 5px #313131",
            maxWidth: '20rem',
            outline: '1px solid #515151',
          }}>
          <h1 style={{ marginBottom: '1rem' }}><strong>Pi Manager</strong></h1>
          <p style={{ marginBottom: '1rem' }}>
            Please login with your Sagebrush Google account below.
          </p>
          <div style={{
            maxWidth: '16rem',
            display: 'flex',
            justifyItems: 'center',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: '#111111',
            padding: '5px 20px',
            borderRadius: '5px',
            outline: '1px solid #3aa3f5',
            cursor: 'pointer'
          }}
            onClick={googleLogin}
          >
            <p style={{ paddingRight: '10px' }}>Login With Google</p>
            <img
              style={{
                objectFit: 'contain',
                maxHeight: '32px',
                borderRadius: '16px',
              }}
              src={logo}
              title='Login'
              alt=''
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LoginPage