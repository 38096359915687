import { atom, selector } from "recoil";
import axios from "axios";

var extension = window.location.origin.endsWith('.work') ? '.work' : '.dev'

const endpoint = 'https://piman.sagebrush' + extension + '/pi_manager_api/User'

/**
   * API Request to see if the provided name is already and existing contentID
   * @param {string} name string that will be used to describe uploaded content
   * @returns {boolean}
   * @function
   */
export const checkContentName = async (name) => {
  return await axios({
    method: 'get',
    url: endpoint + '/CheckContentID',
    withCredentials: true,
    params: {
      contentID: name
    }
  })
    .then((msg) => {
      return msg.data;
    })
}

export const sortByName = (a, b) => {
  if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
  if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
  return 0;
}

/**
 * All the information you need to display content on the content page
 * @typedef ContentInfo
 * @type {object}
 * @property {int} id id of the content in the database
 * @property {string} ContentID identifier/description of the content (user readable)
 * @property {string} ContentPath path to the content
 * @property {string} Hash hash of the content file, unique to each file
 * @property {string} FileType filetype of the content
 * @property {boolean} inUse whether or not the content is being used currently
 */

/**
 * API Request for a list of all the content in the database that can be displayed on a pi.
 * @returns {Array<ContentInfo>}
 * @function
 */
export const getcontentList = async () => {
  return await axios({
    method: 'get',
    url: endpoint + '/getContentList',
    withCredentials: true,
  })
    .then((msg) => {
      return msg.data.sort(sortByName);
    })
}

export const contentAtom = atom({
  key: 'contentAtom',
  default: selector({
    key: 'contentSelector',
    get: async () => {
      return await getcontentList()
    }
  })
})

export const defaultContentAtom = atom({
  key: 'defaultContentAtom',
  default: selector({
    key: 'defaultContentSelector',
    get: async () => {
      return await axios({
        method: 'get',
        url: endpoint + '/getDefaultContent',
        withCredentials: true,
      })
        .then((msg) => {
          return msg.data;
        })
    }
  })
})

/**
 * An item in the contentLoopup database
 * @typedef contentItem
 * @type {object}
 * @property {int} id
 * @property {string} ContentID
 * @property {string} ContentPath
 * @property {string} Hash
 * @property {string} FileType
 */

/**
 * API Call to upload a file with a user entered name to the content database
 * @param {File} file file that was uploaded
 * @param {string} name file description/identifier
 * @returns {contentItem | boolean} if the file already exists, contentItem, otherwise false
 * @function
 */
export const uploadcontent = async (file, name) => {
  const formData = new FormData();
  formData.append("file", file, file.name)
  formData.append("name", name)
  return await axios({
    method: 'post',
    url: endpoint + '/UploadContent',
    headers: { "Content-Type": "multipart/form-data", },
    withCredentials: true,
    data: formData,
    // timeout: 60 * 1000 * 20, // 20 minutes,
    // onUploadProgress: progressEvent => {
    //   let { loaded, total } = progressEvent;
    //   console.log((loaded / total) * 100)
    // }
  })
    .then((msg) => {
      if (msg.data !== 200) {
        return msg.data
      } else {
        return false
      }
    })
}

/**
 * API Call to create a playlist
 * @param {Object<Array<File>>} files array of .mp3/.m4a files
 * @param {string} name name for the created playlist
 * @function
 */
export const createPlaylist = async (files, name) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i], files[i].name)
  }
  formData.append("name", name)
  return await axios({
    method: 'post',
    url: endpoint + '/CreatePlaylist',
    withCredentials: true,
    headers: { "Content-Type": "multipart/form-data", },
    data: formData
  }).then((msg) => {
    if (msg.data !== 200) {
      return msg.data
    } else {
      return false
    }
  })
}

/**
 * API Call to edit a playlist
 * @param {Object<Array<File>>} files array of .mp3/.m4a files
 * @param {string} name name for the edited playlist
 * @param {string} oldID id of old content
 * @function
 */
export const editPlaylist = async (files, name, oldID) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append('files', files[i], files[i].name)
  }
  formData.append("name", name)
  formData.append('oldID', oldID)
  return await axios({
    method: 'post',
    url: endpoint + '/EditPlaylist',
    withCredentials: true,
    headers: { "Content-Type": "multipart/form-data", },
    data: formData
  }).then((msg) => {
    if (msg.data !== 200) {
      return msg.data
    } else {
      return false
    }
  })
}

/**
   * API Request to add a HTML redirect to the database
   * @param {string} url link to a webpage you'd like to be displayed
   * @param {string} name url description/identifier
   * @returns {contentItem | boolean} if the file already exists, contentItem, otherwise false
   * @function
   */
export const addHTMLRedirect = async (url, name) => {
  return await axios({
    method: 'post',
    url: endpoint + '/AddHTMLRedirect',
    withCredentials: true,
    data: {
      url: url,
      name: name
    }
  })
    .then((msg) => {
      if (msg.data !== 200) {
        return msg.data
      } else {
        return false
      }
    })
}

/**
 * @typedef SelectItem
 * @type {object}
 * @prop {string} id
 * @prop {string} name
 */

/**
   * API Request to delete content from the database
   * @param {SelectItem} content
   * @function
   */
export const deleteContent = async (content) => {
  return await axios({
    method: 'post',
    url: endpoint + '/deleteContent',
    withCredentials: true,
    data: content
  })
}

/**
 * @typedef EditContent
 * @type  {object}
 * @prop {string} _id id of content
 * @prop {string} name name of content
 * @prop {string|undefined} oldID id of the old content
 */

/**
 *
 * @param {EditContent} content
 * @returns
 */

export const editContent = async (content) => {
  return await axios({
    method: 'post',
    url: endpoint + '/editContent',
    withCredentials: true,
    data: content
  })
}

export const MAX_FILE_SIZE = 3500000000 // 3500mb