import React from 'react'
import axios from "axios";
import { useRecoilState } from 'recoil';
import {
  groupsAtom,
  userAtom,
  clearUserInfo,
  leadingStorage
} from '../states/auth';
import Cookies from 'js-cookie';

axios.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get(`${leadingStorage}JWT`)
    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const AxiosInterceptor = () => {

  const [groups, setGroups] = useRecoilState(groupsAtom)
  const [user, setUser] = useRecoilState(userAtom)

  const updateUserInfo = (userInfo) => {

    let groups = userInfo.groups

    let user = {
      avatar: userInfo.avatar,
      email: userInfo.email,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      name: userInfo.name
    }

    sessionStorage.setItem(`${leadingStorage}Groups`, JSON.stringify(groups))
    sessionStorage.setItem(`${leadingStorage}UserData`, JSON.stringify(user))

    setGroups(groups)
    setUser(user)

    console.log('reauth')
  }

  const signOut = () => {
    clearUserInfo(window.location.pathname + window.location.search)
  }
  axios.interceptors.response.use(
    async (response) => {
      let auth = response.data.auth
      if (auth) {
        Cookies.remove(`${leadingStorage}JWT`)
        Cookies.set(`${leadingStorage}JWT`, `${auth.token_type} ${auth.access_token}`)
        updateUserInfo(auth.user_info)
      }

      if (response.data.data) { response.data = response.data.data }
      return response
    },
    function (error) {
      if (error.response.status !== 304) {
        signOut();
      }

      return Promise.reject(error);
    }
  )
  return (
    <React.Fragment></React.Fragment>
  )
}

export default AxiosInterceptor