import React from "react";
import loadingBars from "../Assets/Loading/loading-bars.gif";

const LoadingBars = (props) => {
  return (
    <React.Fragment>
      <img src={loadingBars} width={props.width || `16px`} />
    </React.Fragment>
  );
};

export default LoadingBars;
