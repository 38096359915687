import React, { useEffect, useState, useRef } from 'react'

import {
  piInfoAtom,
  getPiInfo,
  createTask,
  updatePiEditables,
  piCommands,
  adminDeletePi,
  getTimePast,
  adminDeleteAndTransferPi
} from '../../states/client'

import useWindowDimensions from '../../components/useWindowDimensions';

import { useRecoilState, useRecoilValue } from 'recoil'

import { useNavigate } from "react-router-dom";

import LoadingBars from '../../components/Loading/LoadingBars';

import { Formik, Form, Field } from 'formik';

import Loading from '../../components/Loading/Loading';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'

import '../../components/GenericPage/page.css'
import './clients.css'
import { hasAdminAccess } from '../../states/auth';
import { defaultContentAtom } from '../../states/content';
import { getScheduleWhen } from '../../states/scheduler';
import Select from 'react-select'

library.add(fas)

const ClientsComp = ({ setPageName, toggleSidebar }) => {

  const sortByName = (a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    if (a.toLowerCase() > b.toLowerCase()) return 1;
    return 0;
  }
  const history = useNavigate();

  const [clients, setClients] = useRecoilState(piInfoAtom)
  const defaultContent = useRecoilValue(defaultContentAtom)

  const searched = window.location.search?.split('?')[1]?.split('_') || []
  const searchedClientID = searched[0] ? searched[0] : null
  const searchedTab = searched[1] ? searched[1] : 'info'
  const currentClient = clients.find(item => item._id === searchedClientID)

  const [selectedClientTab, setSelectedClientTab] = useState(searchedTab)

  const [selectedGroup, setSelectedGroup] = useState('All')
  const clientGroups = ['All', ...new Set(((clients.map(item => { return item.Group })).filter(item => item !== 'Unsorted')).sort(sortByName)), 'Unsorted']
  const [selectedClient, setSelectedClient] = useState(currentClient ? currentClient : null)
  const [serverLogs, setServerLogs] = useState(currentClient ? currentClient.ServersLogs : [])
  const [clientLogs, setClientLogs] = useState(currentClient ? currentClient.PiLogs : [])
  const [logRefresh, setLogRefresh] = useState(true)

  const listClients = clients.map(opt => {
    return {
      value: opt._id,
      label: opt.Location ? `${opt.Name} - ${opt.Location}` : opt.Name,
      NameID: {
        id: opt._id,
        Name: opt.Name,
      }
    }
  })

  const [deletingClient, setDeletingClient] = useState(false)

  const [clientSearch, setClientSearch] = useState('')

  const { height, width } = useWindowDimensions()
  const adjustedWidth = toggleSidebar ? width - 245 : width

  const selectGroup = (value) => {
    if (value !== selectedGroup) {
      setSelectedGroup(value)
    }
  }

  const selectClient = (client) => {
    if (selectedClient) {
      if (client._id !== selectedClient._id) {
        setSelectedClient(client)
        setClientLogs(client.PiLogs)
        setServerLogs(client.ServersLogs)
      }
    } else {
      setSelectedClient(client)
      setClientLogs(client.PiLogs)
      setServerLogs(client.ServersLogs)
    }
    history(`${window.location.pathname}?${client._id}_${searchedTab}`)
  }

  const filterClients = (c = clients) => {
    if (selectedGroup === 'All') {
      return c
    }
    return clients.filter(item => item.Group === selectedGroup)
  }

  const searchClients = (c = clients) => {
    return c.filter(item => item.Name.toLowerCase().includes(clientSearch) ||
      item.Group.toLowerCase().includes(clientSearch) ||
      item.Location.toLowerCase().includes(clientSearch) ||
      item.TimePast.toLowerCase().includes(clientSearch) ||
      item.ClientVersion.toLowerCase().includes(clientSearch) ||
      item.IP.join('').includes(clientSearch) ||
      item._id.toLowerCase().includes(clientSearch))
  }

  const updateClients = async () => {
    const newClients = await getPiInfo()
    if (selectedClient) {
      let selected = newClients.find(item => item._id === selectedClient._id)
      if (selected) {
        setSelectedClient(selected)
        if (logRefresh) {
          setClientLogs(selected.PiLogs)
          setServerLogs(selected.ServersLogs)
        }
      } else {
        setSelectedClient(null)
      }
    }
    setClients(newClients)
  }

  const updateVal = () => {
    if (val === 0) {
      setVal(1)
    } else {
      setVal(0)
    }
  }

  const [val, setVal] = useState(0)

  useEffect(() => {
    const timer = setTimeout(updateVal, 1000 * 10) // update very 10 seconds
    updateClients()
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  useEffect(() => {
    setPageName('Clients');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {deletingClient && (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          zIndex: '2000',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          width: '100%',
          top: '0',
          left: '0',
          height: '100vh'
        }}>
          <Loading color={'white'} />
        </div>)}
      <div className='pageBody' style={{ maxWidth: '700px' }}>
        <div className='pageLayoutBoxes'>
          <div className='clientInformation' style={{ color: '#eeeeee' }}>
            {selectedClient && (
              <Client
                client={selectedClient}
                serverLogs={serverLogs}
                clientLogs={clientLogs}
                logRefresh={logRefresh}
                selectedClientTab={selectedClientTab}
                setSelectedClientTab={setSelectedClientTab}
                setLogRefresh={setLogRefresh}
                setDeletingClient={setDeletingClient}
                defaultContent={defaultContent}
                updateClients={updateClients}
                clients={listClients}
              />
            )}
          </div>
          <div className='clientBox'>
            <div className='clientGroupSelection'>
              <h3
                style={{
                  color: '#eeeeee',
                  textDecoration: 'underline',
                  marginLeft: '10px',
                  marginBottom: '5px',
                  paddingTop: '5px'
                }}>
                Groups:
              </h3>
              {clientGroups.map((item, index) => {
                return (
                  <p
                    key={index}
                    className='clientGroupSelectionItem'
                    style={selectedGroup === item ? { color: '#3aa3f5', cursor: 'default' } : {}}
                    onClick={(e) => {
                      e.preventDefault()
                      selectGroup(item)
                    }}
                  >
                    {item}
                  </p>
                )
              })}
            </div>
            <div className='clientSelection'>
              <div className='clientSelectionFilter'>
                <h3
                  style={{
                    color: '#eeeeee',
                    marginLeft: '5px',
                  }}>
                  Clients:
                </h3>
                <input
                  className='clientListSearchField'
                  type="text"
                  placeholder='Search...'
                  value={clientSearch}
                  onChange={(e) => setClientSearch(e.target.value.toLowerCase())}
                />
              </div>
              <div className='clientSelectionScrollable'>
                {clients.length > 0 ? <>
                  {searchClients(filterClients(clients)).length > 0 ? <>
                    {searchClients(filterClients(clients)).map(client => {
                      return (
                        <div
                          className='clientSelectionOption'
                          style={selectedClient?._id === client._id ? {
                            color: '#f4f4f4',
                            borderBottom: '1px solid #2b7fbe'
                          }
                            :
                            {}}
                          key={client._id}
                          onClick={async (e) => {
                            e.preventDefault()
                            selectClient(client)
                            if (selectedClientTab === 'edit') {
                              await setSelectedClientTab('')
                              await setSelectedClientTab('edit')
                            }
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%' }}>
                              <h4>
                                {client.Name}
                              </h4>
                              <div style={{
                                display: 'flex',
                                fontSize: '11px',
                                paddingTop: '5px'
                              }}>
                                <p style={{ borderRight: '1px solid #444444', flex: 1 }}>
                                  IP: {client.IP.find(item => item.includes('10.')) ? client.IP.find(item => item.includes('10.')) : client.IP[0]}<br />
                                  Connected: {client.TimePast}  ago
                                </p>
                                <p style={{ paddingLeft: '5px', flex: 1 }}>
                                  {client.Location === '' ? `Group: ${client.Group}` : `Location: ${client.Location}`}<br />
                                  Version: {client.ClientVersion}
                                </p>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
                              {!client.PiSS ? <p style={{ fontSize: '12px', margin: '0px 10px' }}>Missing</p> :
                                <DisplayImage src={client.PiSS} height={adjustedWidth > 450 ? '60px' : '80px'} width={adjustedWidth > 450 ? '110px' : '80px'} />
                              }
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </> :
                    <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Clients Matching Search.</p>
                  }
                </>
                  :
                  <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Clients.</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Clients = ({ setPageName, toggleSidebar }) => {
  return (
    <React.Suspense fallback={<Loading color={'white'} />}>
      <ClientsComp setPageName={setPageName} toggleSidebar={toggleSidebar} />
    </React.Suspense>
  )
}

export default Clients

const EditClient = ({ client, updateClients }) => {
  const initialValues = {
    group: client.Group,
    location: client.Location,
    notes: client.Note,
    id: client._id
  }

  const submitForm = async (values, client) => {
    let update = {
      id: client._id,
    }

    let changes = false
    if (values.group !== client.Group) {
      if (values.group.trim().length === 0) {
        update.Group = 'Unsorted'
        changes = true
      } else {
        update.Group = values.group.trim()
        changes = true
      }
    }

    if (values.location !== client.Location) {
      update.Location = values.location.trim()
      changes = true
    }

    if (values.notes !== client.Note) {
      update.Note = values.notes
      changes = true
    }
    if (changes) {
      await updatePiEditables(update)
      updateClients()
    }
    return
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validate={(values) => { return {} }}
        onSubmit={async (values, { resetForm }) => {
          await submitForm(values, client)
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <p style={{ paddingLeft: '5px', paddingTop: '5px' }}>Group:</p>
              <Field
                className='clientListSearchField'
                style={{ marginLeft: '5px' }}
                type='text'
                placeholder={client.Group}
                name='group'
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <p style={{ paddingLeft: '5px' }}>Location:</p>
              <Field
                className='clientListSearchField'
                style={{ marginLeft: '5px' }}
                type='text'
                placeholder={client.Location}
                name='location'
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p style={{ paddingLeft: '5px' }}>Notes:</p>
              <Field
                className='clientNoteField'
                style={{ marginLeft: '5px' }}
                component='textarea'
                rows="4"
                placeholder={client.Notes}
                name='notes'
              />
            </div>
            <button
              className='clientListSearchField'
              type='submit'
              disabled={isSubmitting}
              style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '5px' }}
            >
              {isSubmitting ?
                <div style={{ textAlign: 'center', width: '46px' }}><LoadingBars /></div>
                :
                'Submit'
              }
            </button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const Client = ({ client, serverLogs, clientLogs, logRefresh, setLogRefresh, selectedClientTab, setSelectedClientTab, setDeletingClient, defaultContent, updateClients, clients }) => {
  const [toggleTimestamp, setToggleTimestamp] = useState(true)
  const listClients = clients.filter(c => c.value !== client._id)


  const [selectedTransferClient, setSelectedTransferClient] = useState(listClients.length > 0 ? listClients[0] : null)

  const commands = useRecoilValue(piCommands)
  const clientCommands = client.TVStatus === 'UnsupportedTV' || client.TVStatus === '' ? commands.UnsupportedTV : commands.Default

  const history = useNavigate();

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({ block: "nearest", inline: "nearest" }));
    return <div ref={elementRef} />;
  };

  const clientTabSelection = (tab) => {
    if (tab !== selectedClientTab) {
      history(`${window.location.pathname}?${client._id}_${tab}`)
      setSelectedClientTab(tab)
    }
  }

  const submitDeleteClient = async () => {
    if (hasAdminAccess()) {
      setDeletingClient(true)
      await adminDeletePi({
        id: client._id,
        name: client.Name
      })
      setDeletingClient(false)
      window.location = '/Clients'
    } else {
      window.alert("You don't have access to delete a client.")
    }
  }

  const submitTransferAndDeleteClient = async () => {
    if (hasAdminAccess()) {
      if (selectedTransferClient) {
        setDeletingClient(true)
        await adminDeleteAndTransferPi({
          id: client._id,
          name: client.Name,
          newID: selectedTransferClient.NameID.id,
          newName: selectedTransferClient.NameID.Name
        })
        setDeletingClient(false)
        window.location = '/Clients'
      } else {
        window.alert("You must select a client to transfer the schedules to.")
      }
    } else {
      window.alert("You don't have access to delete a client.")
    }
  }

  const submitClientCommand = async (piID, name, command) => {
    setDeletingClient(true)
    if (window.confirm(`Run Command '${command}' on client '${name}'`)) {
      await createTask({
        PiID: piID,
        PiName: name,
        Command: command,
        Priority: 1
      })
    }
    setDeletingClient(false)
  }

  const formatEndDate = () => {
    var endDate = client.PiSchedule.Days.EndDate.split('-')
    return `${endDate[1]}/${endDate[2]}/${endDate[0]}`
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', height: '100%', color: '#eeeeee' }}>


        <div style={{ flex: '3', borderRight: '1px solid #eeeeee', height: '100%', padding: '0px 5px', overflow: 'auto' }}>
          <h3 className='clientSelectionItemName'><strong>{client.Name}</strong></h3>

          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>IP:</p>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {client.IP.map((item, index) => {
                return (
                  <div key={index} style={{ display: 'flex' }}>
                    {item !== '\n' &&
                      (
                        <>
                          {index !== 0 && (
                            <div style={{ borderRight: '1px solid #206294', marginRight: '5px', paddingLeft: '5px' }} />
                          )}
                          <p>{item}</p>
                        </>
                      )}
                  </div>
                )
              })}
            </div>
          </div>

          {client.Hardware && (<div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Hardware:</p>
            <p>
              {client.Hardware}
            </p>
          </div>)}

          {client.Load && (<div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Load:</p>
            <p>
              {client.Load}
            </p>
          </div>)}

          {client.Uptime && (<div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Uptime:</p>
            <p>
              {client.Uptime}
            </p>
          </div>)}

          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Last Connect:</p>
            <p>
              {client.TimePast} ago
            </p>
          </div>
          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Version:</p>
            <p>
              {client.ClientVersion}
            </p>
          </div>

          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Screen:</p>
            <p>
              {client.ScreenResolution}
            </p>
          </div>

          {(client.TVStatus) && (
            <div
              className='clientSelectionItem clientSelectionItemSpacing'
              style={{ flexWrap: 'wrap' }}
            >
              <p className='clientSelectionItemHeader'>TV Status:</p>
              <p>
                {client.TVStatus}
              </p>
            </div>)}

          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Location:</p>
            <p>
              {client.Location ? client.Location : 'Not Set'}
            </p>
          </div>

          <div
            className='clientSelectionItem clientSelectionItemSpacing'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Group:</p>
            <p>
              {client.Group}
            </p>
          </div>

          <div
            className='clientSelectionItem'
            style={{ flexWrap: 'wrap' }}
          >
            <p className='clientSelectionItemHeader'>Notes:</p>
            <p>
              {client.Note}
            </p>
          </div>


        </div>
        <div className='clientSelectionTabs'>

          <button
            title='Info'
            className='clientSelectionIcon'
            style={selectedClientTab === 'info' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('info')}
          >
            <FontAwesomeIcon icon="fa-solid fa-info" />
          </button>

          <button
            className='clientSelectionIcon'
            title='Edit'
            style={selectedClientTab === 'edit' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('edit')}
          >
            <FontAwesomeIcon icon="fa-solid fa-pencil" />
          </button>

          <button
            className='clientSelectionIcon'
            title='Server Logs'
            style={selectedClientTab === 'server' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('server')}
          >
            <FontAwesomeIcon icon="fa-solid fa-server" />
          </button>


          <button
            className='clientSelectionIcon'
            title='Client Logs'
            style={selectedClientTab === 'client' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('client')}
          >
            <FontAwesomeIcon icon="fa-solid fa-display" />
          </button>

          <button
            className='clientSelectionIcon'
            title='Commands'
            style={selectedClientTab === 'reboot' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
            onClick={(e) => clientTabSelection('reboot')}
          >
            <FontAwesomeIcon icon="fa-solid fa-terminal" />
          </button>

          {hasAdminAccess() && (
            <button
              className='clientSelectionIcon'
              title='Delete'
              style={selectedClientTab === 'delete' ? { color: '#3aa3f5', borderColor: '#2b7fbe' } : {}}
              onClick={(e) => clientTabSelection('delete')}
            >
              <FontAwesomeIcon icon="fa-solid fa-trash" />
            </button>)}
        </div>
        <div style={{ flex: '5', overflow: 'auto' }}>

          {selectedClientTab === 'info' && (
            <div className='clientLogBox' style={{ overflow: 'auto' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div>
                  <span>Content:</span>
                  <div className='clientInfoImageOutline'>
                    {client.PiSchedule ?
                      <DisplayImage
                        src={client.PiSchedule.Content.Path}
                        thumbnail={client.PiSchedule.Content.Thumbnail} />
                      :
                      <DisplayImage src={defaultContent.Path} />
                    }
                  </div>
                  <p style={{ fontSize: '10px' }}>Last Updated: {client.TimePast} ago</p>
                </div>

                <div>
                  <span>Screenshot:</span>
                  <div className='clientInfoImageOutline'>
                    {client.ss === '' ? <p style={{ fontSize: '12px' }}>Missing</p> :
                      <DisplayImage src={client.PiSS} />
                    }
                  </div>
                  <p style={{ fontSize: '10px' }}>Last Updated: {getTimePast(client.PiSSUpdated)} ago</p>
                </div>
              </div>

              <div style={{ borderTop: '1px solid #2b7fbe', marginTop: '10px', borderBottom: '1px solid #2b7fbe', fontSize: '12px' }}>
                <h4 style={{ fontSize: '16px' }}>Schedule:</h4>
              </div>
              <div style={{ fontSize: '12px' }}>
                {client.PiSchedule ?
                  <div className='clientScheduleBox'>
                    <span style={{ fontSize: '16px' }}><strong>{client.PiSchedule.Name}</strong></span>
                    <span><span style={{ color: '#3aa3f5' }}>Content: </span>{client.PiSchedule.Content.Name}</span>
                    <div style={{ display: 'flex' }}>
                      <span style={{ color: '#3aa3f5', paddingRight: '5px', minWidth: '41px' }}>When:</span>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {getScheduleWhen(client.PiSchedule).map((item, index) => {
                          return (<span key={index} style={{ paddingRight: '4px' }}>{item}</span>)
                        })}
                      </div>
                    </div>
                    {client.PiSchedule.Days.EndDate && client.PiSchedule.ScheduleType === 'Recurring' &&
                      (<span><span style={{ color: '#3aa3f5' }}>End Date: </span>{formatEndDate()}</span>)}

                  </div>
                  :
                  <p>
                    Nothing Scheduled.
                  </p>
                }
              </div>
            </div>
          )}

          {selectedClientTab === 'edit' && (
            <EditClient client={client} updateClients={updateClients} />
          )}

          {selectedClientTab === 'server' && (
            <div className='clientLogBox'>
              <div style={{ borderBottom: '1px solid #acacac', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <p>Server Logs:</p>
                <div style={{ display: 'flex', fontSize: '12px' }}>
                  <input
                    style={{ cursor: 'pointer' }}
                    checked={logRefresh}
                    onChange={(e) => setLogRefresh(e.target.checked)}
                    type='checkbox'
                  />
                  <p style={{ margin: '3px 5px 0px 2px' }}>Refresh</p>
                  <input
                    checked={toggleTimestamp}
                    style={{ cursor: 'pointer' }}
                    onChange={(e) => setToggleTimestamp(e.target.checked)}
                    type='checkbox'
                  />
                  <p style={{ margin: '3px 5px 0px 2px' }}>Timestamps</p>
                </div>
              </div>
              <div style={{ fontSize: '11px', overflow: 'auto', height: 'calc(100% - 16px)' }}>
                {serverLogs.map((log, index) => {
                  return (
                    <div key={`${log.timestamp}-${index}`}>
                      <p>{toggleTimestamp &&
                        (<span style={{ color: '#3aa3f5' }}>{log.timestamp}: </span>)}
                        {log.log}
                      </p>
                    </div>
                  )
                })}
                {logRefresh && (<AlwaysScrollToBottom />)}
              </div>

            </div>
          )}

          {selectedClientTab === 'client' && (
            <div className='clientLogBox'>
              <div style={{ borderBottom: '1px solid #acacac', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <p>Client Logs:</p>
                <div style={{ display: 'flex', fontSize: '12px' }}>
                  <input
                    style={{ cursor: 'pointer' }}
                    checked={logRefresh}
                    onChange={(e) => setLogRefresh(e.target.checked)}
                    type='checkbox'
                  />
                  <p style={{ margin: '3px 5px 0px 2px' }}>Refresh</p>
                  <input
                    checked={toggleTimestamp}
                    style={{ cursor: 'pointer' }}
                    onChange={(e) => setToggleTimestamp(e.target.checked)}
                    type='checkbox'
                  />
                  <p style={{ margin: '3px 5px 0px 2px' }}>Timestamps</p>
                </div>
              </div>
              <div style={{ fontSize: '11px', overflow: 'auto', height: 'calc(100% - 16px)' }}>
                {clientLogs.map((log, index) => {
                  return (
                    <div key={`${log.timestamp}-${index}`}>
                      <p>{toggleTimestamp &&
                        (<span style={{ color: '#3aa3f5' }}>{log.timestamp}: </span>)}
                        {log.log}
                      </p>
                    </div>
                  )
                })}
                {logRefresh && (<AlwaysScrollToBottom />)}
              </div>

            </div>
          )}

          {selectedClientTab === 'reboot' && (
            <div style={{ margin: '7px 10px' }}>
              <p>Client Commands: </p>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {clientCommands.map(item => {
                  return (
                    <button
                      key={item.value}
                      className='clientListSearchField'
                      type='submit'
                      onClick={(e) => { submitClientCommand(client._id, client.Name, item.label) }}
                      style={{ cursor: 'pointer', marginTop: '10px' }}
                    >
                      {item.label}
                    </button>
                  )
                })}
              </div>
            </div>
          )}

          {selectedClientTab === 'delete' && hasAdminAccess() && (
            <div style={{ margin: '7px 10px' }}>
              <p>Delete client '{client.Name}' and all of its associated schedules?</p>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <button
                  className='clientListSearchField'
                  type='submit'
                  onClick={(e) => { submitDeleteClient() }}
                  style={{ cursor: 'pointer', marginTop: '10px' }}
                >
                  DELETE
                </button>
              </div>
              {listClients.length > 0 && (
                <>
                  <div style={{ borderBottom: '1px solid #888', margin: '10px 0px' }} />
                  <span>Delete client '{client.Name}' and transfer all it's schedules to:</span>
                  <SelectTransferClient options={listClients} value={selectedTransferClient} setValue={setSelectedTransferClient} />

                  <div style={{ display: 'flex', justifyContent: 'right' }}>
                    <button
                      className='clientListSearchField'
                      type='submit'
                      disabled={selectedTransferClient === null}
                      onClick={(e) => { submitTransferAndDeleteClient() }}
                      style={{ cursor: 'pointer', marginTop: '10px' }}
                    >
                      TRANSFER & DELETE
                    </button>

                  </div>
                </>)}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

const DisplayImage = ({ src, thumbnail, height, width }) => {
  const maxHeight = height ? height : '90px'
  const maxWidth = width ? width : '160px'
  return (
    <div style={{
      maxHeight: maxHeight,
      maxWidth: maxWidth,
      overflow: 'hidden',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <a
        href={src}
        target='_blank'
        rel="noopener noreferrer"
        aria-label='link'
      >
        <img
          style={{
            objectFit: 'contain',
            maxHeight: maxHeight,
            maxWidth: maxWidth,
            color: 'white',
            textDecoration: 'underline',
            marginTop: '7px'
          }}
          src={thumbnail ? thumbnail : src}
          title=""
          alt='Link'
        />
      </a>
    </div>
  )
}

const SelectTransferClient = ({ value, setValue, options, height = '200px' }) => {
  const style = {
    control: (provided, isFocused, isSelected) => ({
      ...provided,
      background: '#303030',
      color: 'white',
      maxWidth: '350px'
    }),
    menu: provided => ({
      ...provided,
      marginTop: 0,
      background: '#181818',
      color: 'white',
    }),
    menuList: provided => ({
      ...provided,
      background: '#181818',
      color: 'white',
      maxHeight: height,
    }),
    option: (provided, { isDisabled, isFocused, isSelected }) => {
      return {
        ...provided,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? '#383838'
            : isFocused
              ? '#212121'
              : undefined,
        color: 'white',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...provided[':active'],
          backgroundColor: !isDisabled
            ? '#212121'
            : undefined,
        },
      };
    },
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#515151'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#cccccc',
      ':hover': {
        color: '#2b7fbe',
        cursor: 'pointer',
        backgroundColor: '#414141'
      },
    })

  }
  return (
    <Select
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral80: 'white',
          neutral30: '#2b7fbe',
          primary: '#3aa3f5',
        }
      })}
      name={'Transfer To'}
      styles={style}
      options={options}
      value={value}
      onChange={(newVal) => {
        setValue(newVal)
      }}
    />
  )
}