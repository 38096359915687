import Select from 'react-select'

const FormikSelect = ({ options, field, form, height = '250px', isMulti = false }) => {
  const style = {
    control: (provided, isFocused, isSelected) => ({
      ...provided,
      background: '#303030',
      color: 'white',
    }),
    menu: provided => ({
      ...provided,
      marginTop: 0,
      background: '#181818',
      color: 'white',
    }),
    menuList: provided => ({
      ...provided,
      background: '#181818',
      color: 'white',
      maxHeight: height,
    }),
    option: (provided, { isDisabled, isFocused, isSelected }) => {
      return {
        ...provided,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? '#383838'
            : isFocused
              ? '#212121'
              : undefined,
        color: 'white',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...provided[':active'],
          backgroundColor: !isDisabled
            ? '#212121'
            : undefined,
        },
      };
    },
    singleValue: (provided) => ({
      ...provided,
      color: 'white'
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#515151'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#cccccc',
      ':hover': {
        color: '#2b7fbe',
        cursor: 'pointer',
        backgroundColor: '#414141'
      },
    })

  }

  const formatOptions = () => {
    var newOptions = []

    options.forEach(opt => {
      newOptions.push({
        value: opt._id,
        label: opt.Location ? `${opt.Name} - ${opt.Location}` : opt.Name,
        NameID: {
          id: opt._id,
          Name: opt.Name,
          Path: opt.Path ? opt.Path : undefined,
          Thumbnail: opt.Thumbnail ? opt.Thumbnail : undefined
        }
      })
    });
    return newOptions
  }

  const opt = formatOptions()
  return (
    <Select
      isMulti={isMulti}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral80: 'white',
          neutral30: '#2b7fbe',
          primary: '#3aa3f5',
        }
      })}
      name={field.name}
      onChange={async (value) => {
        if (isMulti) {
          let vals = value.map(item => item.NameID)
          form.setFieldValue(field.name, vals)
        } else {
          var val = value.NameID
          // options.forEach(opt => {
          //   if (opt._id === value) {
          //     val = {
          //       id: opt._id,
          //       Name: opt.Name,
          //     }
          //     if (opt.Path) {
          //       val.Path = opt.Path
          //     }
          //     if (opt.Thumbnail) {
          //       val.Thumbnail = opt.Thumbnail
          //     }
          //   }
          // })
          form.setFieldValue(field.name, val)
        }
      }}
      styles={style}
      options={opt}
      value={(() => {
        if (!opt) return '';
        if (!field.value) return '';
        if (isMulti) {
          if (Array.isArray(field.value)) {

            let values = []
            field.value.forEach(item => {
              let temp = opt.find(i => i.value === item.id)
              if (temp) {
                values.push(temp)
              }
            })
            return values
          } else {
            let temp = opt.find(i => i.value === field.value.id)
            if (temp) {
              return [temp]
            } else {
              return ''
            }
          }
        } else {
          for (let optionsLength = opt.length, i = 0; i < optionsLength; i++) {
            const option = opt[i];
            if (option.value === field.value.id) {
              return option
            }
          }
        }
        return '';
      })()}
    />
  )
}

export default FormikSelect