import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';

import "./app.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

import LoginPage from './views/Login/LoginPage';
import Dashboard from './views/Dashboard/Dashboard';
import Scripts from './views/Scripts/Scripts';
import Clients from './views/Clients/Clients';
import Schedules from './views/Schedules/Schedules';



import { hasAdminAccess, hasUserAccess } from "./states/auth";

import AxiosInterceptor from './components/AxiosInterceptor';
import Content from './views/Content/Content';
import useWindowDimensions from './components/useWindowDimensions';

/**
 * Manages all the main components and sets the paths to view them on the pisignage webpage.
 * @component
 */
function App() {

  const AdminRoutes = [
    {
      link: '/',
      name: 'Dashboard'
    },
    {
      link: '/Schedules',
      name: 'Schedules'
    },
    {
      link: '/Content',
      name: 'Content'
    },
    {
      link: '/Scripts',
      name: 'Scripts'
    },
    {
      link: '/Clients',
      name: 'Clients'
    }
  ]

  const userRoutes = [
    {
      link: '/',
      name: 'Dashboard'
    },
    {
      link: '/Schedules',
      name: 'Schedules'
    },
    {
      link: '/Content',
      name: 'Content'
    },
    {
      link: '/Clients',
      name: 'Clients'
    }
  ]

  const { height, width } = useWindowDimensions()

  const [toggleSidebar, setToggleSidebar] = useState(width > 450)
  const [currentPageName, setCurrentPageName] = useState('')
  const admin = hasAdminAccess()
  const user = hasUserAccess()
  const routes = admin ? AdminRoutes : hasUserAccess ? userRoutes : []

  const CLIENT_ID =
    "256362504823-4eauh409mnktiv0h05jdjq5m89nmg6n7.apps.googleusercontent.com";

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <AxiosInterceptor />
      <Router>
        <div className='pageBackground'>
          <div className='fullPage'>
            <Sidebar routes={routes} toggleSidebar={toggleSidebar} />
            <div className='webpage'>
              <Topbar
                setToggleSidebar={setToggleSidebar}
                pageName={currentPageName}
              />
              <div style={{ display: 'flex', overflow: 'hidden' }}>
                <Routes>
                  <Route path='/auth' element={<LoginPage />} />
                  {admin && (<>
                    <Route path='/Schedules' element={<Schedules setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                    <Route path='/Scripts' element={<Scripts setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                    <Route path='/Clients' element={<Clients setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                    <Route path='/Content' element={<Content setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                  </>)}

                  {user && (<>
                    <Route path='/Schedules' element={<Schedules setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                    <Route path='/Clients' element={<Clients setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                    <Route path='/Content' element={<Content setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                  </>)}

                  {admin || user ?
                    <>
                      <Route path='/' element={<Dashboard setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                      <Route path='*' element={<Dashboard setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                    </> : <>
                      <Route path='*' element={<LoginPage />} />
                    </>
                  }
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </Router>
      {/* <Router>
        <div>
          <Topbar validateUser={validateUser} userInfo={userInfo} />
          <div>
            <div className="formWindow">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Home />} />
                {userInfo ? <>

                  {userInfo.Authorized === "User" || userInfo.Authorized === "Admin" ? <>
                    <Route path="/PiManager" element={<UserPi auth={userInfo.Authorized} />} />
                    <Route path="/Media" element={<UserMediaManager links={mediaLinks} />} />
                    <Route path="/Media/Upload" element={<UserMediaUpload links={mediaLinks} />} />
                    <Route path="/Media/AddWebpage" element={<UserAddHTMLRedirect links={mediaLinks} />} />
                    <Route path="/Media/CreatePlaylist" element={<UserCreatePlaylist links={mediaLinks} />} />
                    <Route path="/Schedule" element={<UserSchedules links={scheduleLinks} />} />
                    <Route path="/Schedule/Create" element={<UserCreateSchedule links={scheduleLinks} />} />
                  </> : <></>}

                  {userInfo.Authorized === "Admin" ? <>
                    <Route path="/Media/Scripts" element={<AdminScriptManager links={mediaLinks} />} />
                    <Route path="/Media/UploadScript" element={<AdminUploadScript links={mediaLinks} />} />
                  </> : <></>}

                </> : <></>}
              </Routes>
            </div>
          </div>
        </div>
      </Router> */}
    </GoogleOAuthProvider>
  );
}

export default App;
