import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';

import './scripts.css'
import '../../components/GenericPage/page.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import uploadingGif from '../../components/Assets/Uploading/Uploading.gif'

import {
  scriptsAtom,
  uploadScript,
  getListOfScripts,
  deleteScript,
  getExtensions,
  extensionsAtom,
  addScriptToExtension
} from '../../states/scripts';

import { useNavigate } from "react-router-dom";

import { useRecoilState } from 'recoil';
import update from "immutability-helper";

import Loading from '../../components/Loading/Loading';
import useWindowDimensions from '../../components/useWindowDimensions';
import { MAX_FILE_SIZE } from '../../states/content';
import FormikSelect from '../../components/FormikSelect';
import LoadingBars from '../../components/Loading/LoadingBars';

library.add(fas)

const ScriptsComp = ({ setPageName, toggleSidebar }) => {

  const history = useNavigate();

  const { height, width } = useWindowDimensions()

  const searched = window.location.search?.split('?')[1] || 'Scripts'

  const [extensions, setExtensions] = useRecoilState(extensionsAtom)

  const [scripts, setScripts] = useRecoilState(scriptsAtom)
  const [selectedOption, setSelectedOption] = useState(searched)
  const [search, setSearch] = useState({
    name: '',
    desc: '',
    id: ''
  })

  const [extSearch, setExtSearch] = useState({
    ext: '',
    script: ''
  })

  const emptyNewScript = {
    name: '',
    desc: '',
    file: '',
    ext: ''
  }

  const emptyNewExtention = {
    _id: '',
    Extension: '',
    ScriptID: '',
    ScriptName: ''
  }

  const updateSearch = (val) => {
    setSearch(prev => {
      return update(prev, {
        $merge: val
      })
    })
  }

  const updateExtSearch = (val) => {
    setExtSearch(prev => {
      return update(prev, {
        $merge: val
      })
    })
  }

  const [scriptPopup, setScriptPopup] = useState(scripts.length === 0 ? true : false)
  const [selectedScript, setSelectedScript] = useState(emptyNewScript)
  const [selectedExtention, setSelectedExtention] = useState(emptyNewExtention)
  const [toggleFilter, setToggleFilter] = useState(false)

  const adjustedWidth = toggleSidebar ? width - 245 : width

  const updateAtoms = async () => {
    setScripts(await getListOfScripts())
    setExtensions(await getExtensions())
    return
  }

  const submitForm = async (values) => {

    let script = {
      file: values.file,
      name: values.name,
      desc: values.desc,
      ext: values.ext !== '' ? values.ext : 'None'
    }

    var resp = await uploadScript(script)
    if (resp !== 200) {
      alert(`Script has already been uploaded, look at: ${resp.Name}`)
    }
    await updateAtoms()
    setSelectedScript(emptyNewScript)
    return
  }

  const submitExtension = async (values) => {
    var ext = {
      Extension: values.Extension,
      ScriptID: values.Script.id,
      ScriptName: values.Script.Name
    }

    await addScriptToExtension(ext)
    setSelectedExtention(emptyNewExtention)
    await updateAtoms()
    return
  }

  const handleDeleteScript = async (script) => {
    let resp = await deleteScript({
      id: script._id,
      name: script.Name
    })
    if (resp !== 200) {
      alert(resp)
    }
    await updateAtoms()
  }

  const selectOption = (option) => {
    if (option !== selectedOption) {
      setSelectedOption(option)
      history(`${window.location.pathname}?${option}`)
    }
  }

  const searchScripts = (scripts) => {
    return scripts.filter(s => s.Name.toLowerCase().includes(search.name.toLowerCase()) &&
      s._id.includes(search.id.toLowerCase()) &&
      s.Description.toLowerCase().includes(search.desc.toLowerCase()))
  }

  const searchExtensions = (ext) => {
    return ext.filter(s => s.Extension.toLowerCase().includes(extSearch.ext.toLowerCase()) &&
      s.ScriptName.includes(extSearch.script.toLowerCase()))
  }

  useEffect(() => {
    setPageName('Scripts')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div
        className='pageBody'
        style={{ overflow: 'auto' }}
      >
        <div
          style={{ maxWidth: '1200px' }}
        >
          <div className='scriptStickyBox'>
            <div className='scriptOptionBar'>
              <div style={{ display: 'flex' }}>
                <ScriptOptionBarButton
                  buttonStyle='scriptButton'
                  text='Scripts'
                  setVal={selectOption}
                  val={selectedOption}
                />
                <ScriptOptionBarButton
                  buttonStyle='scriptButton'
                  text='Extensions'
                  setVal={selectOption}
                  val={selectedOption}
                />
              </div>
              <div
                className='scriptButtonEnd'
                style={{ padding: '2px 5px', color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' }}
                onClick={() => {
                  setSelectedScript(emptyNewScript)
                  setSelectedExtention(emptyNewExtention)
                  setScriptPopup(!scriptPopup)
                }}
              >
                <FontAwesomeIcon style={{ width: '15px' }} icon={scriptPopup ? "fa-solid fa-xmark" : "fa-solid fa-plus"} />
              </div>
            </div>
          </div>
          <div className='scriptPageInterfacesScaling'>

            {selectedOption === 'Scripts' && (
              <>
                <ScriptPopup
                  trigger={scriptPopup}
                  setTrigger={setScriptPopup}
                  script={selectedScript}
                  submitForm={submitForm}
                />
                {scripts.length > 0 ?
                  <div className='scriptListTableScaling'>

                    <div className='scriptListTopbar'
                      style={{
                        height: toggleFilter ? width > 530 ? '103px' : '136px' : '30px',
                      }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid #a5a5a5', }}>
                        <div className='scriptListTopbarItem'>
                          <span>Scripts</span>
                        </div>
                        <div className='scriptListTopbarItem' style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <FontAwesomeIcon
                            onClick={() => {
                              setToggleFilter(!toggleFilter)
                            }}
                            title={toggleFilter ? "Close" : "Filter"}
                            className='scriptFilter'
                            icon={toggleFilter ? "fa-solid fa-xmark" : "fa-solid fa-filter"} />
                        </div>
                      </div>
                      <div className='scriptListTopbarItem'
                        style={{
                          transition: 'all 1000ms cubic-bezier(.7,0,.3,1)',
                          opacity: toggleFilter ? 1 : 0,
                          padding: '6px 5px',
                          flexWrap: 'wrap',
                          justifyContent: 'normal',
                          fontSize: '14px',
                          fontWeight: 'normal'
                        }}>
                        {toggleFilter && (
                          <>
                            <div style={{ flex: 1 }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>Name:</span>
                                <input
                                  className='scriptListSearchField'
                                  type="text"
                                  placeholder='Search...'
                                  value={search.name}
                                  onChange={(e) => updateSearch({ name: e.target.value.toLowerCase() })}
                                />
                              </div>

                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>ID:</span>
                                <input
                                  className='scriptListSearchField'
                                  type="text"
                                  placeholder='Search...'
                                  value={search.id}
                                  onChange={(e) => updateSearch({ id: e.target.value.toLowerCase() })}
                                />
                              </div>
                            </div>

                            <div style={{ flex: 1 }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>Description:</span>
                                <input
                                  className='scriptListSearchField'
                                  type="text"
                                  placeholder='Search...'
                                  value={search.desc}
                                  onChange={(e) => updateSearch({ desc: e.target.value.toLowerCase() })}
                                />
                              </div>
                            </div>
                          </>)}
                      </div>
                    </div>

                    {searchScripts(scripts).length > 0 ?
                      <div className='scriptListBox'>
                        {searchScripts(scripts).map((script) => (
                          <ScriptListEntry
                            key={script._id}
                            script={script}
                            handleDeleteScript={handleDeleteScript}
                            width={adjustedWidth}
                            extensions={extensions}
                          />
                        ))}
                      </div>
                      :
                      <p style={{ textAlign: 'center', color: '#dddddd', margin: '5px 0px' }}>No Scripts Matching Search.</p>
                    }
                  </div>
                  :
                  <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Scripts.</p>
                }
              </>
            )}

            {selectedOption === 'Extensions' && (
              <>
                <ExtensionPopup
                  trigger={scriptPopup}
                  setTrigger={setScriptPopup}
                  extension={selectedExtention}
                  submitForm={submitExtension}
                  scripts={scripts}
                />
                {extensions.length > 0 ?
                  <div className='scriptListTableScaling'>

                    <div className='scriptListTopbar'
                      style={{
                        height: toggleFilter ? width > 530 ? '73px' : '104px' : '30px',
                      }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid #a5a5a5', }}>
                        <div className='scriptListTopbarItem'>
                          <span>Extensions</span>
                        </div>
                        <div className='scriptListTopbarItem' style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <FontAwesomeIcon
                            onClick={() => {
                              setToggleFilter(!toggleFilter)
                            }}
                            title={toggleFilter ? "Close" : "Filter"}
                            className='scriptFilter'
                            icon={toggleFilter ? "fa-solid fa-xmark" : "fa-solid fa-filter"} />
                        </div>
                      </div>
                      <div className='scriptListTopbarItem'
                        style={{
                          transition: 'all 1000ms cubic-bezier(.7,0,.3,1)',
                          opacity: toggleFilter ? 1 : 0,
                          padding: '6px 5px',
                          flexWrap: 'wrap',
                          justifyContent: 'normal',
                          fontSize: '14px',
                          fontWeight: 'normal'
                        }}>
                        {toggleFilter && (
                          <>
                            <div style={{ flex: 1 }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>Extension:</span>
                                <input
                                  className='scriptListSearchField'
                                  type="text"
                                  placeholder='Search...'
                                  value={extSearch.ext}
                                  onChange={(e) => updateExtSearch({ ext: e.target.value.toLowerCase() })}
                                />
                              </div>
                            </div>
                            <div style={{ flex: 1 }}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>Script:</span>
                                <input
                                  className='scriptListSearchField'
                                  type="text"
                                  placeholder='Search...'
                                  value={extSearch.script}
                                  onChange={(e) => updateExtSearch({ script: e.target.value.toLowerCase() })}
                                />
                              </div>
                            </div>
                          </>)}
                      </div>
                    </div>

                    {searchExtensions(extensions).length > 0 ?
                      <div className='scriptListBox'>
                        {searchExtensions(extensions).map((ext) => (
                          <ExtensionListEntry
                            key={ext._id}
                            extension={ext}
                            width={adjustedWidth}
                            setSelectedExtention={setSelectedExtention}
                            setScriptPopup={setScriptPopup}
                          />
                        ))}
                      </div>
                      :
                      <p style={{ textAlign: 'center', color: '#dddddd', margin: '5px 0px' }}>No Extensions Matching Search.</p>
                    }
                  </div>
                  :
                  <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Extensions.</p>
                }
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Scripts = ({ setPageName, toggleSidebar }) => {
  return (
    <React.Suspense fallback={<Loading color={'white'} />}>
      <ScriptsComp setPageName={setPageName} toggleSidebar={toggleSidebar} />
    </React.Suspense>
  )
}

export default Scripts

const ScriptOptionBarButton = ({ text, buttonStyle, setVal, val }) => {
  return (
    <div
      className={buttonStyle}
      style={text === val ? { color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' } : {}}
      onClick={() => setVal(text)}
    >
      {text}
    </div>
  )
}

const ExtensionListEntry = ({ extension, width, setScriptPopup, setSelectedExtention }) => {
  return (
    <div className='scriptListItem'>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div className='scriptListItemEntry' style={{ padding: '2px 5px', fontSize: width > 450 ? '13px' : '11px' }}>
          <span style={{ fontSize: '16px' }}><strong>{extension.Extension}</strong></span>
          <span><span style={{ color: '#3aa3f5' }}>Script: </span>{extension.ScriptName}</span>
        </div>
        <div
          className='scriptListItemEntry'
          style={{ borderLeft: '1px solid #a5a5a5', width: '25px', justifyContent: 'space-evenly' }}
        >
          <FontAwesomeIcon
            title='Edit'
            className='scheduleListItemEntryIcon'
            icon="fa-solid fa-pen-to-square"
            onClick={async (e) => {
              setSelectedExtention(extension)
              await setScriptPopup(false)
              setScriptPopup(true)
            }}
          />

        </div>
      </div>
    </div >
  )
}

const ScriptListEntry = ({ script, handleDeleteScript, width, extensions }) => {
  return (
    <div className='scriptListItem'>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div className='scriptListItemEntry' style={{ padding: '2px 5px', fontSize: width > 450 ? '13px' : '11px' }}>
          <span style={{ fontSize: '16px' }}><strong>{script.Name}</strong></span>
          <span><span style={{ color: '#3aa3f5' }}>ID: </span>{script._id}</span>
          <span><span style={{ color: '#3aa3f5' }}>Description: </span>{script.Description}</span>
        </div>
        <div
          className='scriptListItemEntry'
          style={{ borderLeft: '1px solid #a5a5a5', width: '25px', justifyContent: 'space-evenly' }}
        >
          {script.Path !== 'No Script Needed' && (
            <>
              {!extensions.find(item => item.ScriptID === script._id) && (
                <FontAwesomeIcon
                  title='Delete'
                  className='scriptListItemEntryIcon'
                  icon="fa-solid fa-trash-can"
                  onClick={(e) => handleDeleteScript(script)}
                />)}

              <a
                className="scriptListItemEntryIcon"
                href={script.Path}
                download={true}
              >
                <FontAwesomeIcon
                  title='Download'
                  icon="fa-solid fa-download" />
              </a>
            </>
          )}
        </div>
      </div>
    </div >
  )
}

const ScriptPopup = ({ trigger, setTrigger, submitForm, script }) => {
  const [uploading, setUploading] = useState(false)
  const initialValues = {
    ...script,
  }
  const validateFormData = (values) => {
    var errors = {}
    if (!(values.name).trim()) {
      errors.name = 'Required'
    }

    if (!(values.desc).trim()) {
      errors.desc = 'Required'
    }

    if (!(values.file) && values.id === '') {
      errors.file = 'Required'
    } else if (values.file.size > MAX_FILE_SIZE) {
      errors.file = `Max Upload Size ${MAX_FILE_SIZE / 1000000}mb.`
    }

    return errors
  }
  return (
    <div style={{
      maxHeight: trigger ? '800px' : '0px',
      opacity: trigger ? 1 : 0,
      transition: 'all 1000ms ease',
      overflow: 'hidden',
      marginBottom: '10px',
      border: trigger ? '1px solid #dddddd' : 'none',
      backgroundColor: '#212121',
    }}>
      {trigger ?
        <div className='scriptPopup'>
          <div className='scriptPopUpBox'>
            <Formik
              initialValues={initialValues}
              validate={validateFormData}
              onSubmit={async (values, { resetForm }) => {
                setUploading(true)
                await submitForm(values)
                setUploading(false)
                if (values.id !== '') {
                  setTrigger(false)
                }
                resetForm()
              }}
            >
              {({ isSubmitting }) => (
                <Form className='scriptPopupForm'>
                  <div style={{ padding: '0px 5px 5px 5px' }}>
                    {uploading ?
                      <React.Fragment>
                        <p>
                          <img src={uploadingGif} alt='Uploading...' />
                        </p>
                      </React.Fragment>
                      :
                      <>
                        <h3 className='scriptPopupHeader'>
                          New Script
                        </h3>

                        <div className='scriptPopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Name:</label>
                          <div>
                            <Field
                              className='scriptInputField'
                              type="text"
                              name="name"
                            />
                            <ErrorMessage name="name" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        <div className='scriptPopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Description:</label>
                          <div>
                            <Field
                              className='scriptInputField'
                              type="text"
                              name="desc"
                            />
                            <ErrorMessage name="desc" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        <div className='scriptPopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>File:</label>
                          <div>
                            <Field
                              component={UploadFile}
                              name="file"
                            />
                            <ErrorMessage name="file" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        <div className='scriptPopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Extension:</label>
                          <div>
                            <Field
                              className='scriptInputField'
                              type="text"
                              name="ext"
                            />
                            <ErrorMsgPopup msg={'Optional'} />
                          </div>
                        </div>

                        <button
                          className='scriptInputField'
                          type="submit"
                          disabled={isSubmitting}
                          style={{ cursor: 'pointer' }}
                        >
                          Submit
                        </button>
                      </>}
                  </div>
                </Form>
              )}

            </Formik>
          </div>
        </div>
        : <div style={{ height: '217px' }}></div>}
    </div>
  )
}

const ExtensionPopup = ({ trigger, setTrigger, submitForm, extension, scripts }) => {


  const initialValues = {
    ...extension,
    Script: extension.ScriptID ? {
      id: extension.ScriptID,
      Name: extension.ScriptName
    } : null,
  }
  const validateFormData = (values) => {
    var errors = {}

    if (!(values.Extension).trim()) {
      errors.Extension = 'Required'
    }

    if (!values.Script) {
      errors.Script = 'Required'
    }

    return errors
  }

  return (
    <div style={{
      maxHeight: trigger ? '627px' : '0px',
      opacity: trigger ? 1 : 0,
      transition: 'all 1000ms ease',
      overflow: 'hidden',
      marginBottom: '10px',
      border: trigger ? '1px solid #dddddd' : 'none',
      backgroundColor: '#212121',
    }}>
      {trigger ?
        <div className='scriptPopup'>
          <div className='scriptPopUpBox'>
            <Formik
              initialValues={initialValues}
              validate={validateFormData}
              onSubmit={async (values, { resetForm }) => {
                if (values._id !== '') { setTrigger(false) }
                await submitForm(values)
                resetForm()
              }}
            >
              {({ isSubmitting, values }) => (
                <Form className='scriptPopupForm'>
                  <div style={{ padding: '0px 5px 5px 5px' }}>
                    <h3 className='scriptPopupHeader' style={{ marginBottom: '15px' }}>
                      {values._id !== '' ? 'Edit Extension' : 'New Extension'}
                    </h3>

                    <div className='scriptPopupFormGroups' style={{ marginBottom: '15px' }}>
                      <label style={{ paddingRight: '5px' }}>Script:</label>
                      <div style={{ width: '282px' }}>
                        <Field
                          name='Script'
                          component={FormikSelect}
                          options={scripts}
                          height={'130px'}
                        />
                        <ErrorMessage name="Script" render={msg => <ErrorMsgPopup msg={msg} />} />
                      </div>
                    </div>

                    <div className='scriptPopupFormGroups' style={{ marginBottom: '35px' }}>
                      <label style={{ paddingRight: '5px' }}>Extension:</label>
                      <div>
                        <Field
                          className='scriptInputField'
                          type="text"
                          name="Extension"
                        />
                        <ErrorMessage name="Extension" render={msg => <ErrorMsgPopup msg={msg} />} />
                      </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <button
                        className='scriptInputField'
                        type="submit"
                        disabled={isSubmitting}
                        style={{ cursor: 'pointer' }}
                      >
                        {isSubmitting ?
                          <div style={{ textAlign: 'center', width: '46px' }}><LoadingBars /></div>
                          :
                          'Submit'
                        }
                      </button>
                    </div>
                  </div>
                </Form>
              )}

            </Formik>
          </div>
        </div>
        : <div style={{ height: '239px' }}></div>}
    </div>
  )
}

const ErrorMsgPopup = ({ msg }) => {
  return (
    <div className='invalidPopupBubble'>
      <p>*</p>
      <p>{msg}</p>
    </div>
  )
}

const UploadFile = ({ field, form }) => {
  return (
    <input
      className='scriptInputField'
      style={{ fontSize: '13px', fontWeight: 'normal' }}
      type="file"
      onChange={(e) => {
        form.setFieldValue(field.name, e.target.files[0])
      }}
    />
  )
}