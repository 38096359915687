import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  schedulesAtom,
  getAllSchedules,
  addSchedule,
  updateSchedule,
  deleteSchedule
} from '../../states/scheduler';

import uploadingGif from '../../components/Assets/Uploading/Uploading.gif'

import useWindowDimensions from '../../components/useWindowDimensions';

import { createTask, piInfoAtom } from '../../states/client';
import { MAX_FILE_SIZE, addHTMLRedirect, contentAtom, getcontentList, uploadcontent } from '../../states/content';

import { useRecoilState, useRecoilValue } from 'recoil';
import update from "immutability-helper";

import './schedules.css'
import '../../components/GenericPage/page.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import FormikSelect from '../../components/FormikSelect';
import Loading from '../../components/Loading/Loading';
import LoadingBars from '../../components/Loading/LoadingBars';
import sending from '../../components/Assets/Loading/Sending_Item.gif'
// add icons to the library
library.add(fas)

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const emptySchedule = {
  id: '',
  ScheduleType: 'Recurring',
  ScheduleOption: 'Weekly',
  Name: '',
  Pi: '',
  Content: '',
  StartTime: '',
  EndTime: '',
  Days: {
    StartDate: '',
    EndDate: '',
    DaysOfWeek: '',
    DayOfMonth: ''
  }
}

const SchedulesComp = ({ setPageName, toggleSidebar }) => {

  const { height, width } = useWindowDimensions()

  const adjustedWidth = toggleSidebar ? width - 245 : width

  const pis = useRecoilValue(piInfoAtom)
  const [schedules, setSchedules] = useRecoilState(schedulesAtom)
  const [content, setContent] = useRecoilState(contentAtom)

  const searched = window.location.search?.split('?')[1] || null

  const getSearchedContent = () => {
    if (searched) {
      let c = content.find(item => item._id === searched)
      if (c) {
        return {
          id: c._id,
          Name: c.Name,
          Path: c.Path,
          Thumbnail: c.Thumbnail
        }
      }
    }
    return null
  }
  const searchedContent = getSearchedContent()

  const [toggleFilter, setToggleFilter] = useState(false)

  const [selectedOption, setSelectedOption] = useState('All')

  const [schedulePopup, setSchedulePopup] = useState(schedules.length === 0 ? true : searchedContent ? true : false)
  const [selectedSchedule, setSelectedSchedule] = useState(emptySchedule)
  const [search, setSearch] = useState({
    name: '',
    pi: '',
    content: '',
    when: ''
  })

  const updateAtoms = async () => {
    setSchedules(await getAllSchedules())
    setContent(await getcontentList())
    return
  }

  const submitForm = async (values) => {
    let schedule = {
      ScheduleType: values.ScheduleType,
      Name: values.Name,
      Pi: values.Pi,
      Content: values.Content,
      StartTime: values.StartTime,
      EndTime: values.EndTime,
      Days: {}
    }
    var date
    if (values.ScheduleType === 'Recurring') {
      if (values.ScheduleOption === 1) {
        schedule.ScheduleOption = 'Weekly'
        schedule.Days.DaysOfWeek = values.DaysOfWeek
      } else {
        schedule.ScheduleOption = 'Monthly'
        schedule.Days.DayOfMonth = values.DayOfMonth
      }

      if (values.EndDate) {
        date = values.EndDate.split('-')
        if (date.length === 3) {
          schedule.Days.EndDate = (date[1] + '/' + date[2] + '/' + date[0])
        }
      }
    } else {
      schedule.ScheduleOption = values.ScheduleOption === 1 ? 'Range' : 'Window'
      date = values.StartDate.split('-')
      if (date.length === 3) {
        schedule.Days.StartDate = (date[1] + '/' + date[2] + '/' + date[0])
      }
      date = values.EndDate.split('-')
      if (date.length === 3) {
        schedule.Days.EndDate = (date[1] + '/' + date[2] + '/' + date[0])
      }
    }

    if (Array.isArray(values.Pi)) {
      let hold = values.Pi[0]
      values.Pi.forEach(async (item, index) => {
        if (index !== 0) {
          schedule.Pi = item
          await addSchedule(schedule)
        }
      })
      if (values.id) {
        schedule.id = values.id
        schedule.ScheduleOldType = values.ScheduleOldType
        schedule.Pi = hold
        await handleEditSchedule(schedule)
      } else {
        schedule.Pi = hold
        await addSchedule(schedule)
        await updateAtoms()
      }
    } else {

      if (values.id) {
        schedule.id = values.id
        schedule.ScheduleOldType = values.ScheduleOldType
        await handleEditSchedule(schedule)
      } else {
        await addSchedule(schedule)
        await updateAtoms()
      }
    }

    if (values.Duplicated && !values.clearOnSubmit) {
      let sch = schedule
      sch.id = ''
      sch.clearOnSubmit = false
      values.Duplicated = true
      await setSchedulePopup(false)
      await setSelectedSchedule(sch)
      await setSchedulePopup(true)
    } else if (values.Duplicated && values.clearOnSubmit) {
      await setSchedulePopup(false)
      await setSelectedSchedule(emptySchedule)
    }
    return
  }

  const handleDeleteSchedule = async (schedule) => {
    if (window.confirm(`Delete Schedule: ${schedule.Name}?`)) {
      await deleteSchedule({
        ScheduleType: schedule.ScheduleType,
        ScheduleID: schedule.id,
        ScheduleName: schedule.Name
      })
      await updateAtoms()
    }
  }

  const handleEditSchedule = async (schedule) => {
    await updateSchedule(schedule)
    await updateAtoms()
  }

  const runSchedule = async (schedule) => {
    let task = {
      PiID: schedule.Pi.id,
      PiName: schedule.Pi.Name,
      ScheduleType: schedule.ScheduleType,
      ScheduleID: schedule.id,
      scheduleName: schedule.Name,
      Priority: 0
    }
    let resp = await createTask(task)

    if (resp !== 200) {
      alert(resp)
    }
  }

  const selectOption = (option) => {
    if (option !== selectedOption) {
      setSelectedOption(option)
    }
  }

  const filterSchedules = (schs) => {
    if (selectedOption === 'Today') {
      return schs.filter(s => s.ActiveToday)
    } else if (selectedOption === 'Recurring' || selectedOption === 'Event') {
      return schs.filter(s => s.ScheduleType === selectedOption)

    } else {
      return schs
    }
  }

  const searchSchedules = (schs) => {
    return schs.filter(item => item.Name.toLowerCase().includes(search.name.toLowerCase()) &&
      item.Pi.Name.toLowerCase().includes(search.pi.toLowerCase()) &&
      (item.when.join(' ').toLowerCase()).includes(search.when.toLowerCase()) &&
      item.Content.Name.toLowerCase().includes(search.content.toLowerCase()))
  }

  const updateSearch = (val) => {
    setSearch(prev => {
      return update(prev, {
        $merge: val
      })
    })
  }

  useEffect(() => {
    setPageName('Schedules')
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    emptySchedule.Date = today
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div
        className='pageBody'
        style={{ overflow: 'auto' }}
      >
        <div
          style={{ maxWidth: '1200px' }}
        >
          <div className='scheduleStickyBox'>
            <div className='scheduleOptionBar'>
              <div style={{ display: 'flex' }}>
                <ScheduleOptionBarButton
                  buttonStyle='scheduleButton'
                  text='All'
                  setVal={selectOption}
                  val={selectedOption}
                />
                <ScheduleOptionBarButton
                  buttonStyle='scheduleButton'
                  text='Recurring'
                  setVal={selectOption}
                  val={selectedOption}
                />
                <ScheduleOptionBarButton
                  buttonStyle='scheduleButton'
                  text='Event'
                  setVal={selectOption}
                  val={selectedOption}
                />
                <ScheduleOptionBarButton
                  buttonStyle='scheduleButtonEnd'
                  text='Today'
                  setVal={selectOption}
                  val={selectedOption}
                />
              </div>
              <div
                className='scheduleButtonEnd'
                style={{ padding: '2px 5px', color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' }}
                onClick={() => {
                  setSelectedSchedule(emptySchedule)
                  setSchedulePopup(!schedulePopup)
                }}
              >
                <FontAwesomeIcon style={{ width: '15px' }} icon={schedulePopup ? "fa-solid fa-xmark" : "fa-solid fa-plus"} />
              </div>
            </div>
          </div>
          <div className='schedulePageInterfacesScaling'>
            <SchedulePopup
              trigger={schedulePopup}
              setTrigger={setSchedulePopup}
              schedule={selectedSchedule}
              submitForm={submitForm}
              pis={pis}
              content={content}
              updateAtoms={updateAtoms}
              searchedContent={searchedContent}
            />
            {schedules.length > 0 ?
              <div className='scheduleListTableScaling'>

                <div className='scheduleListTopbar'
                  style={{
                    height: toggleFilter ? width > 530 ? '103px' : '164px' : '30px',
                  }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', borderBottom: '1px solid #a5a5a5', }}>
                    <div className='scheduleListTopbarItem'>
                      <span>Schedules</span>
                    </div>
                    <div className='scheduleListTopbarItem' style={{ flexDirection: 'row' }}>
                      <FontAwesomeIcon
                        onClick={() => {

                          setToggleFilter(!toggleFilter)
                        }}
                        className='scheduleFilter'
                        title={toggleFilter ? "Close" : "Filter"}
                        icon={toggleFilter ? "fa-solid fa-xmark" : "fa-solid fa-filter"} />
                    </div>
                  </div>
                  <div className='scheduleListTopbarItem'
                    style={{
                      transition: 'all 1000ms cubic-bezier(.7,0,.3,1)',
                      opacity: toggleFilter ? 1 : 0,
                      padding: '6px 5px',
                      flexWrap: 'wrap',
                      justifyContent: 'normal',
                      fontSize: '14px',
                      fontWeight: 'normal'
                    }}>
                    {toggleFilter && (
                      <>
                        <div style={{ flex: 1 }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>Name:</span>
                            <input
                              className='scheduleListSearchField'
                              type="text"
                              placeholder='Search...'
                              value={search.name}
                              onChange={(e) => updateSearch({ name: e.target.value.toLowerCase() })}
                            />
                          </div>

                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>Pi:</span>
                            <input
                              className='scheduleListSearchField'
                              type="text"
                              placeholder='Search...'
                              value={search.pi}
                              onChange={(e) => updateSearch({ pi: e.target.value.toLowerCase() })}
                            />
                          </div>
                        </div>

                        <div style={{ flex: 1 }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>Content:</span>
                            <input
                              className='scheduleListSearchField'
                              type="text"
                              placeholder='Search...'
                              value={search.content}
                              onChange={(e) => updateSearch({ content: e.target.value.toLowerCase() })}
                            />
                          </div>

                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ color: '#3aa3f5', paddingRight: '5px' }}>When:</span>
                            <input
                              className='scheduleListSearchField'
                              type="text"
                              placeholder='Search...'
                              value={search.when}
                              onChange={(e) => updateSearch({ when: e.target.value.toLowerCase() })}
                            />
                          </div>
                        </div>
                      </>)}
                  </div>
                </div>
                {filterSchedules(searchSchedules(schedules)).length > 0 ?
                  <div className='scheduleListBox'>
                    {filterSchedules(searchSchedules(schedules)).map((schedule) => (
                      <ScheduleListEntry
                        key={schedule.id}
                        schedule={schedule}
                        setSchedulePopup={setSchedulePopup}
                        setSelectedSchedule={setSelectedSchedule}
                        deleteSchedule={handleDeleteSchedule}
                        runSchedule={runSchedule}
                        width={adjustedWidth}
                      />
                    ))}
                  </div>
                  :
                  <p style={{ textAlign: 'center', color: '#dddddd', margin: '5px 0px' }}>No Schedules Matching Search.</p>
                }

              </div>
              :
              <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Schedules.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const Schedules = ({ setPageName, toggleSidebar }) => {
  return (
    <React.Suspense fallback={<Loading color={'white'} />}>
      <SchedulesComp setPageName={setPageName} toggleSidebar={toggleSidebar} />
    </React.Suspense>
  )
}

const ScheduleListEntry = ({ schedule, setSchedulePopup, setSelectedSchedule, deleteSchedule, runSchedule, width }) => {
  const [toggleLoading, setToggleLoading] = useState(true)

  const runScheduleDelay = async (schedule) => {
    setToggleLoading(false)
    await runSchedule(schedule)
    await delay(500);
    setToggleLoading(true)
  }

  const formatEndDate = () => {
    var endDate = schedule.Days.EndDate.split('-')
    return `${endDate[1]}/${endDate[2]}/${endDate[0]}`
  }
  return (
    <div className='scheduleListItem'>
      <div style={{ borderRight: '1px solid #a5a5a5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <DisplayImage src={schedule.Content.Path} width={width} thumbnail={schedule.Content.Thumbnail} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <div className='scheduleListItemEntry' style={{ padding: '2px 5px', fontSize: width > 450 ? '13px' : '11px' }}>
          <span style={{ fontSize: '16px' }}><strong>{schedule.Name}</strong></span>
          <div style={{ display: 'flex' }}>
            <span style={{ color: '#3aa3f5', paddingRight: '5px', minWidth: '41px' }}>When:</span>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {schedule.when.map((item, index) => {
                return (<span key={index} style={{ paddingRight: '4px' }}>{item}</span>)
              })}
            </div>
          </div>
          {schedule.Days.EndDate && schedule.ScheduleType === 'Recurring' &&
            (<span><span style={{ color: '#3aa3f5' }}>End Date: </span>{formatEndDate()}</span>)}
          <span><span style={{ color: '#3aa3f5' }}>Content: </span>{schedule.Content.Name}</span>
          <span><span style={{ color: '#3aa3f5' }}>Pi: </span>{schedule.Pi.Name}</span>
        </div>
        <div
          className='scheduleListItemEntry'
          style={{ borderLeft: '1px solid #a5a5a5', width: '25px', justifyContent: 'space-evenly' }}
        >
          <FontAwesomeIcon
            title='Delete'
            className='scheduleListItemEntryIcon'
            icon="fa-solid fa-trash-can"
            onClick={(e) => deleteSchedule(schedule)}
          />
          <FontAwesomeIcon
            title='Edit'
            className='scheduleListItemEntryIcon'
            icon="fa-solid fa-pen-to-square"
            onClick={async (e) => {
              setSelectedSchedule(schedule)
              await setSchedulePopup(false)
              setSchedulePopup(true)
            }}
          />

          <FontAwesomeIcon
            className='scheduleListItemEntryIcon'
            title='Duplicate'
            icon="fa-solid fa-clone"
            onClick={async (e) => {
              let sch = {
                id: '',
                ScheduleType: schedule.ScheduleType,
                ScheduleOption: schedule.ScheduleOption,
                Name: schedule.Name,
                Pi: schedule.Pi,
                Content: schedule.Content,
                StartTime: schedule.StartTime,
                EndTime: schedule.EndTime,
                Days: schedule.Days,
                Duplicated: true
              }
              setSelectedSchedule(sch)
              await setSchedulePopup(false)
              setSchedulePopup(true)
            }}
          />
          {toggleLoading ? <FontAwesomeIcon
            className='scheduleListItemEntryIcon'
            title='Run'
            icon="fa-solid fa-play"
            onClick={(e) => runScheduleDelay(schedule)}
          />
            :
            <div style={{ display: 'flex', justifyContent: 'center', padding: '8px 0px' }}>
              <LoadingBars width='14px' />
            </div>
          }
        </div>
      </div>
    </div >
  )
}

const ScheduleOptionBarButton = ({ text, buttonStyle, setVal, val }) => {
  return (
    <div
      className={buttonStyle}
      style={text === val ? { color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' } : {}}
      onClick={() => setVal(text)}
    >
      {text}
    </div>
  )
}

const SchedulePopup = ({ trigger, setTrigger, submitForm, schedule, pis, content, updateAtoms, searchedContent }) => {
  const [uploadErrors, setUploadErrors] = useState({})

  const [uploading, setUploading] = useState(false)

  const uploadTypes = [
    {
      _id: 'Content',
      Name: 'Media'
    },
    {
      _id: 'HTML',
      Name: 'Webpage'
    },
  ]

  const initialValues = {
    ...schedule,
    Content: schedule.Content ? schedule.Content : searchedContent ? searchedContent : schedule.Content,
    ScheduleOldType: schedule.ScheduleType,
    ScheduleOption: schedule.ScheduleOption === 'Weekly' || schedule.ScheduleOption === 'Range' ? 1 : 2,
    StartDate: schedule.Days.StartDate ? schedule.Days.StartDate : '',
    EndDate: schedule.Days.EndDate ? schedule.Days.EndDate : '',
    DaysOfWeek: schedule.Days.DaysOfWeek ? schedule.Days.DaysOfWeek : '',
    DayOfMonth: schedule.Days.DayOfMonth ? schedule.Days.DayOfMonth : '',
    clearOnSubmit: schedule.clearOnSubmit ? schedule.clearOnSubmit : true,
    Duplicated: schedule.Duplicated ? schedule.Duplicated : false,
    contentType: {
      id: 'Content',
      Name: 'Media'
    },
    HTMLRedirect: '',
    File: '',
    contentName: ''
  }

  const validateUpload = (values) => {
    let errors = {}

    if (!values.contentType) {
      errors.contentType = 'Required'
    }

    if (!(values.contentName).trim()) {
      errors.contentName = 'Required'
    }

    if (values.contentType.id === 'Content') {
      if (!values.File) {
        errors.File = 'Required'
      } else if (values.File.size > MAX_FILE_SIZE) {
        errors.File = `Max Upload Size ${MAX_FILE_SIZE / 1000000}mb.`
      }
    }

    if (values.contentType.id === 'HTML') {
      if (!(values.HTMLRedirect).trim()) {
        errors.HTMLRedirect = 'Required'
      }
    }

    return errors
  }

  const validateFormData = (values) => {
    var errors = {}

    if (!values.ScheduleType) {
      errors.scheduleType = 'Required'
    }

    if (!(values.Name).trim()) {
      errors.Name = 'Required'
    }

    if (values.Pi === '') {
      errors.Pi = 'Required'
    }

    if (Array.isArray(values.pi)) {
      if (values.Pi.length === 0) {
        errors.Pi = 'Required'
      }
    }

    if (!values.StartTime) {
      errors.StartTime = 'Required'
    } else if (!/^([01]\d|2[0-3]):([0-5]\d)/g.test(values.StartTime) && values.StartTime.length === 5) {
      errors.StartTime = 'Format must be hh:mm, in military time'
    }

    if (!values.EndTime) {
      errors.EndTime = 'Required'
    } else if (!/^([01]\d|2[0-3]):([0-5]\d)/g.test(values.EndTime) && values.EndTime.length !== 5) {
      errors.EndTime = 'Format must be hh:mm, in military time'
    }

    if (values.Content === '') {
      errors.Content = 'Required'
    }

    // if (values.Content.id === 'upload') {
    //   errors.Content = 'Upload Content'
    // }

    if (values.EndDate !== '') {
      let date = values.EndDate.split('-')

      if (date.length !== 3) {
        errors.EndDate = 'Date Must be MM/DD/YYYY'
      } else if (date[1].length !== 2) {
        errors.EndDate = 'Months must match mm'
      } else if (date[2].length !== 2) {
        errors.EndDate = 'Days must match dd'
      } else if (date[0].length !== 4) {
        errors.EndDate = 'Year must match yyyy'
      }
    }

    if (values.EndTime <= values.StartTime && values.ScheduleType === 'Recurring') {
      errors.EndTime = 'End time must be larger than Start time'
    }

    if (values.ScheduleOption === 1 && values.ScheduleType === 'Recurring') {
      if (values.DaysOfWeek.length === 0) {
        errors.DaysOfWeek = 'Required'
      }
    }
    if (values.ScheduleOption === 2 && values.ScheduleType === 'Recurring') {
      if (values.Day === '' || values.Day === '0') {
        errors.Day = 'Must be a number from 1-31'
      } else if (values.Day > 31 || values.Day < 1) {
        errors.Day = 'Must be a number from 1-31'
      }
    }

    if (values.ScheduleType === 'Event') {
      if (values.StartDate === '') {
        errors.StartDate = 'Required'
      } else {
        let date = values.StartDate.split('-')

        if (date.length !== 3) {
          errors.StartDate = 'Date Must be MM/DD/YYYY'
        } else if (date[1].length !== 2) {
          errors.StartDate = 'Months must match mm'
        } else if (date[2].length !== 2) {
          errors.StartDate = 'Days must match dd'
        } else if (date[0].length !== 4) {
          errors.StartDate = 'Year must match yyyy'
        }
      }

      if (values.EndDate === '') {
        errors.EndDate = 'Required'
      }

      if (values.EndDate < values.StartDate) {
        errors.EndDate = 'End date must be larger than Start date.'
      }

      if (values.EndDate !== '' && values.StartDate !== '') {
        if (values.ScheduleOption === 1) { //time range
          if (values.EndTime <= values.StartTime && values.EndDate === values.StartDate) {
            errors.EndTime = 'End time must be larger than Start time'
          }
        }

        if (values.ScheduleOption === 2) { //time window
          if (values.EndTime <= values.StartTime) {
            errors.EndTime = 'End time must be larger than Start time'
          }
        }
      }
    }
    return errors
  }

  return (
    <div style={{
      maxHeight: trigger ? '1500px' : '0px',
      opacity: trigger ? 1 : 0,
      transition: 'all 1600ms ease',
      overflow: 'auto',
      marginBottom: '10px',
      border: trigger ? '1px solid #dddddd' : 'none',
      backgroundColor: '#212121',
    }}>
      {trigger ?
        <div className='schedulePopup'>
          <div className='schedulePopUpBox'>
            <Formik
              initialValues={initialValues}
              validate={validateFormData}
              onSubmit={async (values, { resetForm, }) => {
                if (values.id !== '') { setTrigger(false) }
                await submitForm(values)

                if (values.clearOnSubmit) {
                  resetForm()
                }
              }}
            >
              {({ isSubmitting, values, handleSubmit, setFieldValue }) => (
                <Form className='schedulePopupForm'>
                  {isSubmitting ? <p style={{ textAlign: "center" }}>
                    <img src={sending} />
                  </p>
                    :
                    <>
                      <div style={{ padding: '0px 5px 5px 5px' }}>
                        <h3 className='schedulePopupHeader'>
                          {values.id !== '' ? 'Edit Schedule' : 'New Schedule'}
                        </h3>

                        <div className='schedulePopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Name:</label>
                          <div>
                            <Field
                              className='scheduleInputField'
                              type="text"
                              name="Name"
                            />
                            <ErrorMessage name="Name" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        <div className='schedulePopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Pi:</label>
                          <div style={{ width: '332px' }}>
                            <Field
                              name='Pi'
                              component={FormikSelect}
                              options={pis}
                              isMulti={true}
                            />
                            <ErrorMessage name="Pi" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        <div className='schedulePopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Content:</label>
                          <div style={{ width: '282px' }}>
                            <Field
                              name='Content'
                              component={FormikSelect}
                              options={[{ _id: 'upload', Name: 'Upload' }, ...content]}
                            />
                            <ErrorMessage name="Content" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        {values.Content.id === 'upload' ?
                          <>
                            {uploading ?
                              <React.Fragment>
                                <p>
                                  <img src={uploadingGif} alt='Uploading...' />
                                </p>
                              </React.Fragment>
                              :
                              <>
                                <div className='schedulePopupFormGroups'>
                                  <label style={{ paddingRight: '5px' }}>Type:</label>
                                  <div style={{ width: '282px' }}>
                                    <Field
                                      name='contentType'
                                      component={FormikSelect}
                                      options={uploadTypes}
                                    />
                                    {(uploadErrors.contentType) && (<ErrorMsgPopup msg={uploadErrors.contentType} />)}
                                  </div>
                                </div>

                                <div className='schedulePopupFormGroups'>
                                  <label style={{ paddingRight: '5px' }}>Name:</label>
                                  <div>
                                    <Field
                                      className='scheduleInputField'
                                      type="text"
                                      name="contentName"
                                    />
                                    {(uploadErrors.contentName) && (<ErrorMsgPopup msg={uploadErrors.contentName} />)}
                                  </div>
                                </div>

                                {values.contentType.id === 'Content' && (
                                  <div className='schedulePopupFormGroups'>
                                    <label style={{ paddingRight: '5px' }}>Content:</label>
                                    <div>
                                      <Field
                                        component={UploadFile}
                                        name="File"
                                      />
                                      {(uploadErrors.File) && (<ErrorMsgPopup msg={uploadErrors.File} />)}
                                    </div>
                                  </div>
                                )}

                                {values.contentType.id === 'HTML' && (
                                  <div className='schedulePopupFormGroups'>
                                    <label style={{ paddingRight: '5px' }}>Link:</label>
                                    <div>
                                      <Field
                                        className='scheduleInputField'
                                        type="text"
                                        name="HTMLRedirect"
                                      />
                                      {(uploadErrors.HTMLRedirect) && (<ErrorMsgPopup msg={uploadErrors.HTMLRedirect} />)}
                                    </div>
                                  </div>
                                )}
                              </>
                            }
                          </>
                          :
                          <>
                            {values.Content.Path &&
                              (<div className='schedulePopupFormGroups'>
                                <label style={{ paddingRight: '5px' }}>Preview:</label>
                                <DisplayImage src={values.Content.Path} thumbnail={values.Content.Thumbnail} />
                              </div>)
                            }
                          </>}


                        <div className='schedulePopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Schedule Type:</label>
                          <div>
                            <div style={{ display: 'flex' }}>
                              <Field
                                value='Recurring'
                                component={FormikButton}
                                name='ScheduleType'
                              />
                              <Field
                                value='Event'
                                component={FormikButton}
                                name='ScheduleType'
                              />
                            </div>
                            <ErrorMessage name="ScheduleType" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        {values.ScheduleType === 'Recurring' ?
                          <>
                            <div className='schedulePopupFormGroups'>
                              <label style={{ paddingRight: '5px' }}>Interval:</label>
                              <div>
                                <div style={{ display: 'flex' }}>
                                  <Field
                                    value={1}
                                    component={FormikButton}
                                    name='ScheduleOption'
                                  />
                                  <Field
                                    value={2}
                                    component={FormikButton}
                                    name='ScheduleOption'
                                  />
                                </div>
                                <ErrorMessage name="ScheduleOption" render={msg => <ErrorMsgPopup msg={msg} />} />
                              </div>
                            </div>
                            <div className='schedulePopupFormGroups'>
                              {values.ScheduleOption === 1 ?
                                <>
                                  <label style={{ paddingRight: '5px' }}>Days:</label>
                                  <div>
                                    <Field
                                      component={FormikWeekSelection}
                                      name='DaysOfWeek'
                                    />
                                    <ErrorMessage name="DaysOfWeek" render={msg => <ErrorMsgPopup msg={msg} />} />
                                  </div>
                                </> :
                                <>
                                  <label style={{ paddingRight: '5px' }}>Day:</label>
                                  <div>
                                    <Field
                                      style={{ width: '35px' }}
                                      className='scheduleInputField'
                                      type="Number"
                                      name="DayOfMonth"
                                    />
                                    <ErrorMessage name="Day" render={msg => <ErrorMsgPopup msg={msg} />} />
                                  </div>
                                </>}
                            </div>
                            <div className='schedulePopupFormGroups'>
                              <label style={{ paddingRight: '5px' }}>End Date:</label>
                              <div>
                                <Field
                                  className='scheduleInputField'
                                  type="date"
                                  name="EndDate"
                                />

                              </div>
                              <div style={{ display: 'flex', alignItems: 'end', marginLeft: '5px' }}>
                                <ErrorMsgPopup msg={'Optional'} />
                              </div>
                            </div>
                          </>
                          :
                          <>
                            <div className='schedulePopupFormGroups'>
                              <label style={{ paddingRight: '5px' }}>Time Type:</label>
                              <div>
                                <div style={{ display: 'flex' }}>
                                  <Field
                                    value={1}
                                    component={FormikButton}
                                    name='ScheduleOption'
                                  />
                                  <Field
                                    value={2}
                                    component={FormikButton}
                                    name='ScheduleOption'
                                  />
                                </div>
                                <ErrorMessage name="ScheduleOption" render={msg => <ErrorMsgPopup msg={msg} />} />
                              </div>
                            </div>
                            <div className='schedulePopupFormGroups'>
                              <label style={{ paddingRight: '5px' }}>Start Date:</label>
                              <div>
                                <Field
                                  className='scheduleInputField'
                                  type="date"
                                  name="StartDate"
                                />
                                <ErrorMessage name="StartDate" render={msg => <ErrorMsgPopup msg={msg} />} />
                              </div>
                            </div>
                            <div className='schedulePopupFormGroups'>
                              <label style={{ paddingRight: '5px' }}>End Date:</label>
                              <div>
                                <Field
                                  className='scheduleInputField'
                                  type="date"
                                  name="EndDate"
                                />
                                <ErrorMessage name="EndDate" render={msg => <ErrorMsgPopup msg={msg} />} />
                              </div>
                            </div>
                          </>
                        }
                        <div className='schedulePopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Start Time:</label>
                          <div>
                            <Field
                              className='scheduleInputField'
                              name='StartTime'
                              type='time'
                            />
                            <ErrorMessage name="StartTime" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>

                        <div className='schedulePopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>End Time:</label>
                          <div>
                            <Field
                              className='scheduleInputField'
                              name='EndTime'
                              type='time'

                            />
                            <ErrorMessage name="EndTime" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>


                          <button
                            className='scheduleInputField'
                            disabled={isSubmitting}
                            type="button"
                            style={{ cursor: 'pointer' }}
                            onClick={async () => {
                              if (values.Content.id === 'upload') {
                                let errors = validateUpload(values)
                                setUploadErrors(errors)
                                if (JSON.stringify(errors) === '{}') {
                                  setUploading(true)
                                  var resp = false
                                  if (values.contentType.id === 'HTML') {
                                    if (!values.HTMLRedirect.startsWith('https://')) {
                                      values.HTMLRedirect = 'https://' + values.HTMLRedirect
                                    }
                                    resp = await addHTMLRedirect(values.HTMLRedirect, values.contentName)

                                  }

                                  if (values.contentType.id === 'Content') {
                                    resp = await uploadcontent(values.File, values.contentName)
                                  }
                                  setUploading(false)

                                  if (resp) {
                                    if (typeof resp === 'string') {
                                      alert(resp)
                                    } else {
                                      if (resp.Message) {
                                        alert(resp.Message)
                                        if (resp.Content.Exists) {
                                          alert(`Content Already Exists: ${resp.Content.Name}.`)
                                        }
                                      } else if (resp.Exists) {
                                        alert(`Content Already Exists: ${resp.Name}.`)
                                      }
                                    }
                                  }

                                  await updateAtoms()
                                  if (resp.Message) {
                                    setFieldValue('Content', {
                                      Name: resp.Content.Name,
                                      Path: resp.Content.Path,
                                      id: resp.Content._id
                                    })
                                    let temp = values
                                    temp.Content = {
                                      Name: resp.Content.Name,
                                      Path: resp.Content.Path,
                                      id: resp.Content._id
                                    }
                                    if (temp.content.id !== 'upload') {
                                      handleSubmit(temp)
                                    }
                                  } else {
                                    setFieldValue('Content', {
                                      Name: resp.Name,
                                      Path: resp.Path,
                                      id: resp._id
                                    })
                                    let temp = values
                                    temp.Content = {
                                      Name: resp.Name,
                                      Path: resp.Path,
                                      id: resp._id
                                    }
                                    if (temp.content.id !== 'upload') {
                                      handleSubmit(temp)
                                    }
                                  }

                                }
                              } else {
                                handleSubmit()
                              }
                            }}
                          >
                            Submit
                          </button>
                          {values.id === '' && (
                            <>
                              <Field
                                component={Checkbox}
                                name='clearOnSubmit'
                              />
                              <p style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '3px' }}>Clear On Submit</p>
                            </>
                          )}
                        </div>
                      </div>
                    </>}
                </Form>
              )}

            </Formik>
          </div>
        </div>
        : <div style={{ height: '462px' }}></div>}
    </div>
  )
}

const UploadFile = ({ field, form }) => {
  return (
    <input
      className='scheduleInputField'
      style={{ fontSize: '13px', fontWeight: 'normal' }}
      type="file"
      onChange={(e) => {
        form.setFieldValue(field.name, e.target.files[0])
      }}
    />
  )
}

const Checkbox = ({ field, form }) => {
  return (
    <input
      style={{ cursor: 'pointer', marginLeft: '10px' }}
      type='checkbox'
      checked={field.value}
      onChange={(e) => form.setFieldValue(field.name, e.target.checked)}
    />
  )
}

const FormikWeekSelection = ({ field, form }) => {
  const dayOfWeek = [
    'Sunday', 'Monday', 'Tuesday',
    'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ]
  const selectDay = (day) => {
    var values = []
    if (field.value) {
      values = [...field.value]
    }
    if (values.includes(day)) {
      values = values.filter(e => e !== day)
    } else {
      values.push(day)
      values.sort(function sortByDay(a, b) {
        return dayOfWeek.indexOf(a) - dayOfWeek.indexOf(b)
      })
    }
    form.setFieldValue(field.name, values)
  }
  const daySelected = (day) => {
    if (field.value) {
      return field.value.includes(day)
    } else {
      return false
    }
  }
  const createButton = (value, buttonStyle) => {
    return <div
      key={value}
      className={buttonStyle}
      style={daySelected(dayOfWeek[value]) ? { color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' } : {}}
      onClick={() => selectDay(dayOfWeek[value])}
    >
      {dayOfWeek[value].substring(0, 2)}
    </div>
  }
  return (
    <div style={{ display: 'flex' }}>
      {dayOfWeek.map((day, index) => (
        index === dayOfWeek.length - 1 ? createButton(index, 'scheduleButtonEnd') : createButton(index, 'scheduleButton')
      ))}
    </div>
  )
}

const FormikButton = ({ value, field, form }) => {
  const getLabel = () => {
    if (form.values.ScheduleType === 'Event') {
      return value === 1 ? 'Range' : 'Window'
    } else {
      return value === 1 ? 'Weekly' : 'Monthly'
    }
  }
  const label = isNaN(value) ? value : getLabel()
  return (
    <div
      className='scheduleButton'
      style=
      {value === field.value ?
        { color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' } :
        {}}
      onClick={() => form.setFieldValue(field.name, value)}
    >
      {label}
    </div>
  )
}

const ErrorMsgPopup = ({ msg }) => {
  return (
    <div className='invalidPopupBubble'>
      <p>*</p>
      <p>{msg}</p>
    </div>
  )
}

const DisplayImage = ({ src, width, thumbnail }) => {
  const maxWidth = width > 450 ? '160px' : '100px'
  const maxHeight = width > 450 ? '90px' : '110px'
  return (
    <div style={{
      maxHeight: maxHeight,
      width: maxWidth,
      overflow: 'hidden',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <a
        href={src}
        target='_blank'
        rel="noopener noreferrer"
        aria-label='link'
      >
        <img
          style={{
            objectFit: 'contain',
            maxHeight: maxHeight,
            maxWidth: maxWidth,
            color: 'white',
            textDecoration: 'underline',
            marginTop: '7px'
          }}
          src={thumbnail ? thumbnail : src}
          title=""
          alt='Link'
        />
      </a>
    </div>
  )
}

export default Schedules