import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import GoogleLogoutButton from './GoogleLogoutButton';

import './topbar.css'

// add icons to the library
library.add(fas)

const Topbar = ({ setToggleSidebar, pageName }) => {

  return (
    <div className='topbarBox'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FontAwesomeIcon
          className='sidebarToggleBars'
          icon="fa-solid fa-bars"
          onClick={() => setToggleSidebar(current => !current)}
        />
        <h1
          style={{ color: 'white', paddingLeft: '25px', fontSize: '25px' }}
        >
          {pageName}
        </h1>
      </div>
      <div>
        <GoogleLogoutButton />
      </div>
    </div>
  )
}

export default Topbar