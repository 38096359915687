import { atom, selector } from "recoil";
import axios from "axios";

var extension = window.location.origin.endsWith('.work') ? '.work' : '.dev'

const endpoint = 'https://piman.sagebrush' + extension + '/pi_manager_api/User'

export const sortByName = (a, b) => {
  if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
  if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
  return 0;
}

/**
 * @typedef NameID
 * @type {object}
 * @prop {string} id
 * @prop {string} Name
 * @prop {string|undefined} Path
 */

/**
 * @typedef DayOptions
 * @type {object}
 * @prop {string|undefined} StartDate
 * @prop {string|undefined} EndDate
 * @prop {Array<string>|undefined} DaysOfWeek
 * @prop {int|undefined} DayOfMonth
 */
/**
 * All the information you need to display a schedule on the front end
 * @typedef Schedule
 * @type {object}
 * @prop {string} id
 * @prop {string|undefined} ScheduleOldType
 * @prop {string} ScheduleType
 * @prop {string} ScheduleOption
 * @prop {string} Name
 * @prop {NameID} Pi
 * @prop {NameID} Content
 * @prop {string} StartTime
 * @prop {string} EndTime
 * @prop {DayOptions} Days
 * @prop {boolean|undefined} ActiveToday
 * @prop {string|undefined} CreatedBy
 */

/**
 * API Request for a list of all schedules
 * @returns {Array<Schedule>}
 * @function
 */
export const getAllSchedules = async () => {
  return await axios({
    method: 'get',
    url: endpoint + '/getAllSchedules',
    withCredentials: true,
  })
    .then((msg) => {

      return (msg.data.map(item => {
        return {
          ...item,
          when: getScheduleWhen(item)
        }
      })).sort(sortByName)
    })
}

/**
 * list of all schedules
 * @returns {Array<Schedule>}
 * @function
 */
export const schedulesAtom = atom({
  key: 'schedulesAtom',
  default: selector({
    key: 'schedulesSelector',
    get: async () => {
      return await getAllSchedules()
    }
  })
})

/**
 * API Request to add a schedule to the Database
 * @param {Schedule} schedule
 * @function
 */
export const addSchedule = async (schedule) => {
  await axios({
    method: 'post',
    url: endpoint + '/addSchedule',
    data: schedule,
    withCredentials: true,
  }).then((msg) => {
    if (msg.data !== 200) {
      alert(msg.data)
    }
    return
  })
}

/**
 * API Request to update/change a schedule already in the database
 * @param {Schedule} schedule
 * @function
 */
export const updateSchedule = async (schedule) => {
  await axios({
    method: 'post',
    url: endpoint + '/updateSchedule',
    withCredentials: true,
    data: schedule
  }).then((msg) => {
    if (msg.data !== 200) {
      alert(msg.data)
    }
    return
  })
}

/**
 * @typedef SelectSchedule
 * @type {object}
 * @prop {string} ScheduleType
 * @prop {string} ScheduleID
 * @prop {string|undefined} ScheduleName
 */

/**
 * API Request to delete a schedule and return the updated list of a pi's schedules
 * @param {SelectSchedule} schedule
 * @function
 */
export const deleteSchedule = async (schedule) => {
  await axios({
    method: 'post',
    url: endpoint + '/deleteSchedule',
    withCredentials: true,
    data: schedule
  }).then((msg) => {
    return
  })
}

const set24to12Hour = (time) => {
  if (time === 12 || time === 24) {
    return 12
  }
  return time % 12
}

const amOrpm = (time) => {
  if (time === 24) {
    return ' AM'
  } else if (time > 11) {
    return ' PM'
  } else {
    return ' AM'
  }
}

export const getScheduleWhen = (schedule) => {
  var start = schedule.StartTime.split(':')
  var end = schedule.EndTime.split(':')

  var startTime = set24to12Hour(parseInt(start[0])) + ':' + start[1] + amOrpm(parseInt(start[0]))
  var endTime = set24to12Hour(parseInt(end[0])) + ':' + end[1] + amOrpm(parseInt(end[0]))

  var startDate, endDate
  switch (schedule.ScheduleOption) {
    case 'Weekly':
      let days = ''
      schedule.Days.DaysOfWeek.forEach((d, index) => {
        days += d.substring(0, 3)
        if (index < schedule.Days.DaysOfWeek.length - 1) {
          days += ', '
        }
      });
      return [days, `${startTime} - ${endTime}`]
    case 'Monthly':
      let day = schedule.Days.DayOfMonth
      var ending = ''
      if (day < 11 || day > 13) {
        switch ((day) % 10) {
          case 1:
            ending = 'st'
            break;
          case 2:
            ending = 'nd'
            break;
          case 3:
            ending = 'rd'
            break;
          default:
            ending = 'th'
        }
      } else {
        ending = 'th'
      }
      return [(day + ending + ' of Every Month'), `${startTime} - ${endTime}`]
    case 'Range':
      startDate = schedule.Days.StartDate.split('-')
      endDate = schedule.Days.EndDate.split('-')
      if (schedule.Days.StartDate === schedule.Days.EndDate) {
        return [`${startDate[1]}/${startDate[2]}/${startDate[0]}`, `${startTime} - ${endTime}`
        ]
      }
      return [`${startDate[1]}/${startDate[2]}/${startDate[0]} ${startTime} -`,
      `${endDate[1]}/${endDate[2]}/${endDate[0]} ${endTime}`
      ]
    case 'Window':
      startDate = schedule.Days.StartDate.split('-')
      endDate = schedule.Days.EndDate.split('-')
      if (schedule.Days.StartDate === schedule.Days.EndDate) {
        return [`${startDate[1]}/${startDate[2]}/${startDate[0]}`, `${startTime} - ${endTime}`
        ]
      }
      return [`${startDate[1]}/${startDate[2]}/${startDate[0]} - ${endDate[1]}/${endDate[2]}/${endDate[0]},`,
      `${startTime} - ${endTime}`
      ]
    default:
  }
  return ['Broken Date']
}
