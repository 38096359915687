import { atom, selector, selectorFamily, useRecoilState } from "recoil";
import axios from "axios";
import Cookies from 'js-cookie';

// used to swap between .dev and .work for development.
var extension = window.location.origin.endsWith('.work') ? 'work' : 'dev'

const endpoint = 'https://piman.sagebrush.' + extension + '/pi_manager_api'

const adminGroup = 'internal-admin-pi-signage@sagebrush.church';

const userGroup = 'internal-pi-signage@sagebrush.church'

export const leadingStorage = `${extension}PiMan`


/**
   * @typedef User
   * @type {object}
   * @prop {String} email email address of the user
   * @prop {String} firstName the first name of the user
   * @prop {String} lastName last name of the user
   * @prop {String} avatar the src of the avatar of the user (from google)
   * @prop {Array<String>} groups the groups the user is a part of
   */

export const clearUserInfo = (returnLoc = null) => {
  sessionStorage.removeItem(`${leadingStorage}Groups`)
  sessionStorage.removeItem(`${leadingStorage}UserData`)
  Cookies.remove(`${leadingStorage}JWT`)
  window.location = '/auth'
  if (returnLoc) {
    sessionStorage.setItem(`${leadingStorage}ReturnLocation`, returnLoc)
  } else {
    sessionStorage.removeItem(`${leadingStorage}ReturnLocation`)
  }
}

export const setUserCredentials = async () => {
  return await axios({
    method: "get",
    url: endpoint + "/auth/ValidateUser",
  })
    .then((msg) => {
      if (msg.status === 200) {
        return msg.data
      } else {
        clearUserInfo()
        return null
      }
    })
    .catch((error) => {
      clearUserInfo()
      return null
    });
}

export const updateGroups = async () => {
  return await axios({
    method: "get",
    url: endpoint + "/auth/getUserGroups",
  }).then((msg) => {
    return msg.data;
  });
}

/**
 * function used to sign out of google.
 * @func
 */
export const pimanLogout = async () => {
  return await axios({
    method: "get",
    url: endpoint + "/auth/Logout",
  }).then((msg) => {
    clearUserInfo()
  }).catch((error) => {
    clearUserInfo()
  });
}

export const sendUserCred = async (credentialResponse) => {
  return await axios({
    method: "post",
    url: endpoint + "/auth/swap_token",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Requested-With": "XMLHttpRequest",
      "X-Google-OAuth2-Type": "client",
    },
    data: credentialResponse,
  }).then((msg) => {
    Cookies.remove(`${leadingStorage}JWT`)
    Cookies.set(`${leadingStorage}JWT`, `${msg.data.token_type} ${msg.data.access_token}`)

    return msg.data.user_info
  });
};

export const groupsAtom = atom({
  key: "groups",
  default: JSON.parse(sessionStorage.getItem(`${leadingStorage}Groups`)) || [],
});

export const userAtom = atom({
  key: "user",
  default: JSON.parse(sessionStorage.getItem(`${leadingStorage}UserData`)) || null,
});

export const hasAdminAccess = () => {
  return JSON.parse(sessionStorage.getItem(`${leadingStorage}Groups`))?.find(item => item === adminGroup) ? true : false || false
}

export const hasUserAccess = () => {
  return JSON.parse(sessionStorage.getItem(`${leadingStorage}Groups`))?.find(item => item === userGroup) ? true : false || false
}