import { atom, selector } from "recoil";
import axios from "axios";

var extension = window.location.origin.endsWith('.work') ? '.work' : '.dev'

const endpoint = 'https://piman.sagebrush' + extension + '/pi_manager_api'

/**
 * @typedef SelectItem
 * @type {object}
 * @prop {string} id
 * @prop {string} name
*/

/**
 *
 * @param {SelectItem} pi
 * @returns
 */
export const adminDeletePi = async (pi) => {
  return await axios({
    method: 'post',
    url: endpoint + '/Admin/DeletePi',
    withCredentials: true,
    data: pi
  })
}

export const adminDeleteAndTransferPi = async (pi) => {
  return await axios({
    method: 'post',
    url: endpoint + '/Admin/TransferAndDeletePi',
    withCredentials: true,
    data: pi
  })
}

export const sortByLastConnect = (a, b) => {
  if (a.LastConnect < b.LastConnect) return -1;
  if (a.LastConnect > b.LastConnect) return 1;
  return 0;
}

export const sortByName = (a, b) => {
  if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
  if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
  return 0;
}

/**
 * @typedef PiCommand
 * @type {object}
 * @property {string} label the name of a command that can be run on the pi
 * @property {int} value the id of a command that can be run on the pi
 */

/**
 * request a list of pi commands from the api
 * @returns {Object<Array<PiCommand>>}
 * @function
 */
export const piCommands = atom({
  key: 'piCommands',
  default: selector({
    key: 'piCommandsSelector',
    get: async () => {
      return await axios({
        method: 'get',
        url: endpoint + '/User/getListOfPiCommands',
        withCredentials: true,
      })
        .then((msg) => {
          return msg.data;
        })
    }
  })
})

/**
 * @typedef SelectSchedule
 * @prop {string} ScheduleType
 * @prop {string} ScheduleID
 * @prop {string|null} ScheduleName
 */

/**
 * @typedef UpdatePiInfo
 * @prop {string} id
 * @prop {string|null} Group
 * @prop {string|null} Location
 * @prop {string|null} Note
 * @prop {SelectSchedule|null} Schedule
 */

/**
 *
 * @param {UpdatePiInfo} piInfo
 */
export const updatePiEditables = async (piInfo) => {
  await axios({
    method: 'post',
    url: endpoint + '/User/UpdatePiEditables',
    withCredentials: true,
    data: piInfo
  })
}

/**
 * @typedef Task
 * @prop {string} PiID
 * @prop {string} PiName
 * @prop {string|undefined} Command
 * @prop {string|undefined} ScheduleType
 * @prop {string|undefined} ScheduleID
 * @prop {string|undefined} ScheduleName
 * @prop {int} Priority
 */

/**
 *
 * @param {Task} task
 */
export const createTask = async (task) => {
  return await axios({
    method: 'post',
    url: endpoint + '/User/createTask',
    withCredentials: true,
    data: task
  }).then((msg) => {
    return msg.data
  })
}

/**
 * a log entry for a pi containing a message as well as a timestamp of when the message was
 * added
 * @typedef Log
 * @type {object}
 * @property {string} log a message identifying a change on a pi
 * @property {string} timestamp date/time of when the message was added
  */

/**
 * All the information you might need to know about a pi.
 * @typedef PiDesc
 * @type {object}
 * @property {int} id the pi's unique id
 * @property {string} Name the pi's unique name
 * @property {string} Group the pi's group
 * @prop {string} Location
 * @prop {string} TVStatus
 * @prop {string} Note
 * @prop {string} ScreenResolution
 * @prop {Array<string>} IP
 * @property {string} ClientVersion the version of the pi client that is running on the pi
 * @property {str} ScheduleID the id of the schedule currently on the pi (-1 if there isn't one)
 * @property {str} ScheduleType whether the current schedule is an event or recurring schedule
 * @prop {Schedule | null} Schedule
 * @property {str} LastConnect number of days, hours, minutes or seconds passed since the pi
 * last connected to the server
 * @prop {Array<Log>} ServersLogs
 * @property {Array<Log>} PiLogs Array of the saved logs for the pi
 * @prop {string} PiSS
 * @prop {string} PiSSUpdated
 */

/**
 * request general information about all of the pi's in the database
 * @returns {Array<PiDesc>}
 * @function
 */
export const getPiInfo = async () => {
  return await axios({
    method: 'get',
    url: endpoint + '/User/piNames',
    withCredentials: true,
  })
    .then((msg) => {
      let clients = msg.data.map(item => {
        return {
          ...item,
          TimePast: getTimePast(item.LastConnect)
        }
      })
      return clients.sort(sortByName);
    })
}

export const piInfoAtom = atom({
  key: 'piInfo',
  default: selector({
    key: 'piInfoSelector',
    get: async () => {
      return await getPiInfo()
    }
  })
})

export const getSystemLogs = async () => {
  return await axios({
    method: 'get',
    url: endpoint + '/User/getServerLogs',
    withCredentials: true,
  })
    .then((msg) => {
      return msg.data;
    })
}

export const systemLogs = atom({
  key: 'systemLogs',
  default: selector({
    key: 'systemLogsSelector',
    get: async () => {
      return await getSystemLogs()
    }
  })
})

export const getTimePast = (lastConnect) => {
  let seconds = (new Date() - new Date(lastConnect)) / 1000
  let minutes = seconds / 60
  let hours = minutes / 60
  let days = hours / 24
  let time = seconds
  let label = 'second'

  if (days >= 1) {
    time = days
    label = 'day'
  } else if (hours >= 1) {
    time = hours
    label = 'hour'
  } else if (minutes >= 1) {
    time = minutes
    label = 'minute'
  }
  time = parseInt(time)
  if (time > 1) {
    label += 's'
  }
  if (time < 0) {
    time = 0
  }
  return `${time} ${label}`
}