import axios from 'axios'
import { atom, selector } from "recoil";

var extension = window.location.origin.endsWith('.work') ? '.work' : '.dev'

const endpoint = 'https://piman.sagebrush' + extension + '/pi_manager_api/Admin'

/**
 * @typedef Script
 * @type {object}
 * @prop {Number} id the unique id of the script in the database
 * @prop {String} FileType the type of file the script is used to run
 * @prop {String} Path the download path of the script
 * @prop {String} Type the filetype of the script itself
 */

/**
 * Get a list of all the scripts in the database.
 * @returns {Array<Script>}
 * @func
 */
export const getListOfScripts = () => {
  return axios({
    method: 'get',
    url: endpoint + '/GetScripts',
    withCredentials: true,
  }).then((msg) => {
    return msg.data
  })
}

export const scriptsAtom = atom({
  key: 'scriptsAtom',
  default: selector({
    key: 'scriptsSelector',
    get: async () => {
      return await getListOfScripts()
    }
  })
})
/**
 * @typedef SelectItem
 * @type {object}
 * @prop {string} id
 * @prop {string} name
 */
/**
 *
 * @param {SelectItem} script
 */
export const deleteScript = async (script) => {
  return await axios({
    method: 'post',
    url: endpoint + '/DeleteScript',
    withCredentials: true,
    data: script
  }).then((msg) => {
    return msg.data;
  })
}

/**
 * @typedef UploadScript
 * @type {object}
 * @prop {File|None} file
 * @prop {string} name
 * @prop {string} desc
 * @prop {string} ext
 */

/**
 *
 * @param {UploadScript} script
 * @returns
 */
export const uploadScript = async (script) => {
  const formData = new FormData();
  formData.append("file", script.file, script.file.name)
  formData.append("name", script.name)
  formData.append("desc", script.desc)
  formData.append("ext", script.ext)

  return await axios({
    method: 'post',
    url: endpoint + '/UploadScript',
    headers: { "Content-Type": "multipart/form-data", },
    withCredentials: true,
    data: formData
  }).then((msg) => {
    return msg.data
  })
}

/**
 *
 * @returns {Array<ScriptLookup>}
 */
export const getExtensions = async () => {
  return await axios({
    method: 'get',
    url: endpoint + '/GetExtensions',
    withCredentials: true,
  }).then((msg) => {
    return msg.data
  })
}

export const extensionsAtom = atom({
  key: 'extensionsAtom',
  default: selector({
    key: 'extensionsSelector',
    get: async () => {
      return await getExtensions()
    }
  })
})

/**
 * @typedef ScriptLookup
 * @type {object}
 * @prop {string|undefined} id
 * @prop {string} Extension
 * @prop {string|undefined} ScriptID
 * @prop {string|undefined} ScriptName
 * @prop {string|undefined} CreatedBy
 */

/**
 *
 * @param {ScriptLookup} addToExt
 */
export const addScriptToExtension = async (addToExt) => {
  return await axios({
    method: 'post',
    url: endpoint + '/AddScriptToExtension',
    withCredentials: true,
    data: addToExt
  })
}