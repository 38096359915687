import React, { useEffect, useState, useRef } from 'react'

import { useRecoilState } from 'recoil';
import {
  piInfoAtom,
  getPiInfo,
  sortByLastConnect,
  getSystemLogs,
  systemLogs
} from '../../states/client';

import Loading from '../../components/Loading/Loading';

import useWindowDimensions from '../../components/useWindowDimensions';

import '../../components/GenericPage/page.css'
import './dashboard.css'
import { hasAdminAccess } from '../../states/auth';

const DashboardComp = ({ setPageName, toggleSidebar }) => {
  const [clients, setClients] = useRecoilState(piInfoAtom)
  const [sLogs, setSystemLogs] = useRecoilState(systemLogs)



  const [toggleTimestamp, setToggleTimestamp] = useState(true)
  const [toggleRefresh, setToggleRefresh] = useState(true)

  const { height, width } = useWindowDimensions()

  const adjustedWidth = toggleSidebar ? width - 245 : width

  const requiredWidth = 650

  const boxSize = adjustedWidth > requiredWidth ? 'calc(50% - 24px)' : 'calc(100% - 24px)'

  const updateAtoms = async () => {
    setClients(await getPiInfo())
    if (toggleRefresh) { setSystemLogs(await getSystemLogs()) }
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({ block: "nearest", inline: "nearest" }));
    return <div ref={elementRef} />;
  };

  useEffect(() => {
    setPageName('Dashboard')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateVal = () => {
    if (val === 0) {
      setVal(1)
    } else {
      setVal(0)
    }
  }

  const [val, setVal] = useState(0)

  useEffect(() => {
    const timer = setTimeout(updateVal, 1000 * 15) // update every 15 seconds
    updateAtoms()
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  const getListOfIPs = () => {
    let csvString = [
      ['Client', 'IP'],
      ...clients.map(c => {
        let ip = c.IP.find(item => item.includes('10.'))
        if (!ip) {
          ip = c.IP[0]
        }
        return [c.Name, ip]
      })
    ].map(e => e.join(","))
      .join("\r\n");

    const csvFile = new Blob([csvString], { type: 'text/csv' })
    const url = window.URL.createObjectURL(csvFile)

    // Creating an anchor(a) tag of HTML
    const a = document.createElement('a')

    // Passing the blob downloading url
    a.setAttribute('href', url)

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute('download', `ClientIPList.csv`);

    // Performing a download with click
    a.click()
  }

  return (
    <React.Fragment>
      <div className='pageBody' style={{ overflow: 'auto', height: 'calc(100vh - 80px)', margin: '0px', padding: '0px' }}>
        <div style={{
          display: 'flex',
          flexDirection: adjustedWidth > requiredWidth ? 'row' : 'column',
          flexWrap: 'wrap',
          margin: toggleSidebar || adjustedWidth > requiredWidth ? '0px 10px' : '0px'
        }}>
          <div className='dashboardInfoBox' style={{ width: boxSize }} >
            <div
              className='dashboardInfoHeaderBox'
              style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <span>System Logs:</span>
              <div style={{ display: 'flex', fontSize: '12px' }}>
                <input
                  style={{ cursor: 'pointer' }}
                  checked={toggleRefresh}
                  onChange={(e) => setToggleRefresh(e.target.checked)}
                  type='checkbox'
                />
                <p style={{ margin: '3px 5px 0px 2px' }}>Refresh</p>
                <input
                  checked={toggleTimestamp}
                  style={{ cursor: 'pointer' }}
                  onChange={(e) => setToggleTimestamp(e.target.checked)}
                  type='checkbox'
                />
                <p style={{ margin: '3px 5px 0px 2px' }}>Timestamps</p>
              </div>
            </div>
            <div className='dashboardInfoBodyBox' style={{ padding: '1px 3px 3px 3px' }}>
              {sLogs.map((log, index) => {
                return (
                  <div key={`${log.timestamp}-${index}`}>
                    <p>{toggleTimestamp &&
                      (<span style={{ color: '#3aa3f5' }}>{log.timestamp}: </span>)}
                      {log.log}
                    </p>
                  </div>
                )
              })}
              {toggleRefresh && (<AlwaysScrollToBottom />)}
            </div>
          </div>
          <div className='dashboardInfoBox' style={{ width: boxSize }}>
            <div className='dashboardInfoHeaderBox' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>Clients:</span>
              {hasAdminAccess() && (<button className='ipListButton' onClick={getListOfIPs}>IP List</button>)}
            </div>
            <div className='dashboardInfoBodyBox'>
              {[...clients].sort(sortByLastConnect).map(item => (
                <ClientEntry key={item._id} client={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  )
}

const ClientEntry = ({ client }) => {
  return (
    <div className='dashboardInfoScheduleEntry' style={{ display: 'flex', padding: '0px' }}>
      <div style={{ borderRight: '1px solid #a5a5a5', display: 'flex' }}>
        <DisplayImage src={client.PiSS} />
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '2px 5px', width: '100%', cursor: 'pointer' }}
        onClick={() => { window.location.href = `/Clients?${client._id}_info` }}
      >
        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{client.Name}</span>
        <div style={{ display: 'flex', flex: '1' }}>
          <span><span style={{ color: '#3aa3f5' }}>IP: </span>
            {client.IP.find(item => item.includes('10.')) ?
              client.IP.find(item => item.includes('10.'))
              : client.IP[0]}
          </span>
          {client.Load && (<span><span style={{ color: '#3aa3f5', marginLeft: '5px' }}>Load: </span>{client.Load}</span>)}
        </div>
        <span style={{ flex: '1' }}><span style={{ color: '#3aa3f5' }}>Last Connected: </span>{client.TimePast} ago</span>
      </div>
    </div>
  )
}

const Dashboard = ({ setPageName, toggleSidebar }) => {
  return (
    <React.Suspense fallback={< Loading color={'white'} />}>
      <DashboardComp setPageName={setPageName} toggleSidebar={toggleSidebar} />
    </React.Suspense >
  )
}

const DisplayImage = ({ src }) => {
  const maxWidth = '100px'
  const maxHeight = '62px'
  return (
    <div style={{
      maxHeight: maxHeight,
      width: maxWidth,
      overflow: 'hidden',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    }}>
      {src ?
        <a
          href={src}
          target='_blank'
          rel="noopener noreferrer"
          aria-label='link'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            style={{
              objectFit: 'contain',
              maxHeight: maxHeight,
              maxWidth: maxWidth,
              color: 'white',
              textDecoration: 'underline',
            }}
            src={src}
            title=""
            alt='Link'
          />
        </a> :
        <span style={{ width: maxWidth }}>
          missing
        </span>
      }

    </div>
  )
}

export default Dashboard